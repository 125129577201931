import * as glMatrix from "gl-matrix";
import Camera from "./3d/camera";
import { fragLoader, gltfLoader } from "./3d/loader";
import Flat from "./3d/materials/flat";
import PBR from "./3d/materials/pbr";
import Model from "./3d/model";
import Primitives, { triangulate } from "./3d/primitives";
import { GLSLSource, glsl_functions, setFunction } from "./glsl";
import DEFINES from "./glsl/utilities/defines";
import Module from "./module";
import Output from "./output";
import Renderer from "./renderer";
import Sandbox from "./sandbox";
import Sequence from "./sequence";
import Buffer, { GL_ELEMENT_ARRAY_BUFFER, GL_UNSIGNED_SHORT } from "./shader/buffer";
import Generate from "./shader/generator";
import Shader, { GL_TRIANGLES } from "./shader/shader";
import TransformFeedback from "./shader/transformFeedback";
import VAO from "./shader/vao";
import Source from "./source";
import Texture from "./texture";
import { array, gridSize, nameOrProps, sleep, texCoords } from "./utils";
class Synth {
    constructor(settings) {
        this.time = 0;
        this.deltaTime = 0;
        this.mouseX = 0;
        this.mouseY = 0;
        this.speed = 1;
        this.bpm = 60;
        this.s = [];
        this.o = [];
        this.seq = [];
        this.t = [];
        this.shaders = []; // list of all shaders to update in tick
        this.textures = []; // list of all created textures
        this.lastTime = 0;
        this.modules = {};
        this.id = Math.random().toString(36).substr(2, 9);
        this.settings = settings;
        this.width = this.settings.width || 400;
        this.height = this.settings.height || this.width;
        this.precision = this.settings.precision || "highp";
        this.sandbox = new Sandbox(this.settings.makeGlobal ? global : this);
        // create offscreen canvas and pass to worker
        const canvas = this.settings.canvas;
        if (!(canvas instanceof OffscreenCanvas))
            canvas.style.imageRendering = "pixelated";
        this.canvas(canvas);
        // export glsl functions to use in editor code
        this.createHelpers();
        glsl_functions
            .filter(g => g.type === "src" || (g.type === "code" && g.codeType === "src"))
            .forEach(func => this.export(func.name, (...args) => new GLSLSource(this, func, args)));
        this.export("require", this.require);
        this.export("include", this.include);
        // export primitives
        Object.keys(Primitives).forEach(key => {
            this.export(key, props => Primitives[key](this, props));
        });
        // export utils to use in editor code (with 'export' we can only read, with 'varyng' we can read and write)
        this.export("time", this.time);
        this.export("deltaTime", this.deltaTime);
        this.export("mouseX", this.mouseX);
        this.export("mouseY", this.mouseY);
        this.sandbox.varyng("speed", this.speed);
        this.sandbox.varyng("bpm", this.bpm);
        this.sandbox.varyng("precision", this.precision);
        this.sandbox.varyng("update", null);
        this.sandbox.varyng("setFunction", setFunction);
        this.export("resize", this.resize.bind(this));
        this.export("render", this.render.bind(this));
        this.export("loadScript", this.loadScript);
        this.export("hush", this.hush.bind(this));
        this.export("module", this.module.bind(this));
        this.export("texture", props => new Texture(this, nameOrProps(props)));
        this.export("cubemap", src => this.renderer.createCubeTexture(src));
        this.export("buffer", props => new Buffer(this, nameOrProps(props)));
        this.export("vao", props => new VAO(this, nameOrProps(props)));
        this.export("baseVao", () => Shader.defaultGydraVAO);
        this.export("transformFeedback", props => new TransformFeedback(this, props));
        this.export("triangulate", triangulate);
        this.export("shader", props => new Shader(this, nameOrProps(props)));
        this.export("camera", (type, options, controlTo) => type ? new Camera(this, type, options, controlTo) : this._camera);
        this.export("model", props => new Model(this, nameOrProps(props)));
        this.export("gltf", path => gltfLoader(this, path));
        this.export("frag", (path, props) => fragLoader(this, path, nameOrProps(props)));
        this.export("flat", () => new Flat(this));
        this.export("pbr", props => new PBR(this, props));
        this.export("renderer", this.renderer);
        this.export("gridSize", count => gridSize(count));
        Object.keys(glMatrix).forEach(key => this.export(key, glMatrix[key]));
        Object.getOwnPropertyNames(Math).forEach(key => this.export(key, Math[key]));
        this.export("synth", this);
        this.export("log", console.log.bind(console));
        this.export("tick", this.tick.bind(this));
        this.export("array", array);
        this.export("texCoords", texCoords);
        this.export("sleep", sleep);
        /////////////////////
        this.autoTick = this.autoTick.bind(this);
        this.settings.autoTick && this.start();
    }
    export(method, value) {
        this.sandbox.export(method, value);
    }
    canvas(canvas) {
        const started = !!this.tickItv;
        started && cancelAnimationFrame(this.tickItv);
        this.renderer = new Renderer({
            id: this.id,
            canvas,
            debug: this.settings.debug,
        });
        Shader.defaultGydraVAO = new VAO(this, {
            name: "baseVao",
            attrs: {
                position: {
                    data: new Float32Array([-1, 1, 0, 1, 1, 0, -1, -1, 0, 1, -1, 0]),
                    size: 3,
                },
            },
            cells: {
                data: new Uint16Array([0, 2, 3, 0, 3, 1]),
                size: 1,
                type: GL_UNSIGNED_SHORT,
                target: GL_ELEMENT_ARRAY_BUFFER,
            },
            elements: 6,
            primitive: GL_TRIANGLES,
        });
        Shader.defaultGydraShader.vao = Shader.defaultGydraVAO;
        this.export("gl", this.renderer.gl);
        this._camera = new Camera(this, "perspective", {}, this.settings.cameraControl ? this.settings.cameraControl : canvas instanceof OffscreenCanvas ? null : canvas);
        // create sources and outputs
        for (let i = 0; i < this.settings.outputs; i++) {
            delete this.o[i];
            this.o[i] = new Output(this, i);
            this.export("o" + i, this.o[i]);
        }
        this.defaultOutput = this.o[0];
        this.renderOutput = this.defaultOutput;
        // TODO: SourceProxy
        for (let i = 0; i < this.settings.sources; i++) {
            delete this.s[i];
            this.s[i] = new Source(this, i);
            this.export("s" + i, this.s[i]);
        }
        for (let i = 0; i < this.settings.sequences; i++) {
            delete this.seq[i];
            this.seq[i] = new Sequence(this, i);
            this.export("seq" + i, this.seq[i]);
        }
        //////
        // create drawer for output and all outputs
        this.drawer = new Shader(this, {
            name: "drawer",
            ...Shader.defaultGydraShader,
            fragment: `#version 300 es
				precision ${this.precision} float;
				uniform sampler2D tex;
				in vec3 vPosition;
				out vec4 fragColor;
				void main() {
					fragColor = texture(tex, vPosition.xy);
					//fragColor = vec4(vec3(vPosition.xy, 0.0), 1.0);
				}
			`,
        }).out();
        this.drawerAll = new Shader(this, {
            name: "drawer_all",
            ...Shader.defaultGydraShader,
            fragment: generateDynamicShader(this.precision, this.settings.outputs),
        }).out();
        ////
        this.resize(this.width, this.height);
        this.autoTick = this.autoTick.bind(this);
        started && this.settings.autoTick && (this.tickItv = requestAnimationFrame(this.autoTick));
        this.render(this.renderOutput);
    }
    createHelpers() {
        const help = {};
        let lang = "";
        glsl_functions.forEach(func => {
            const helpMessage = (() => {
                let description = func.name + "\n";
                description += func.lang ? "lang: " + func.lang + "\n" : "";
                description += "help" in func ? func.help + "\n\n" : "";
                if (func.type === "code")
                    return `raw code of type ${func.codeType}`;
                const inputs = Generate.sanitizeInput(func.inputs).reduce((acc, input) => {
                    acc[input.name] = [input.default, input.description];
                    return acc;
                }, {});
                return `${description}usage:\n${func.name}(${Object.keys(inputs)
                    .map(key => `\n\t${key}=${Array.isArray(inputs[key][0]) ? `[${inputs[key][0]}]` : inputs[key][0]}${inputs[key][1] ? " // " + inputs[key][1] : ""}`)
                    .join(", ")}\n)`;
            })();
            help[func.name] = () => console.log(helpMessage);
            if (func.lang) {
                lang += `${func.lang}: ${func.name}\n`;
            }
        });
        help["modules"] = () => console.log(`modules:

				usage: [module].help()
${Object.keys(this.modules)
            .map(key => `\t${key}`)
            .join("\n")}`);
        this.export("help", help);
        this.export("lang", lang);
    }
    render(out) {
        if (typeof out === "undefined")
            out = this.o.filter(o => o.attachedShader);
        this.o.forEach(o => o.activate(false));
        // this.s.forEach(s => (s.needsUpdate = false))
        this.shaders.forEach(s => s.activate(false));
        // this.textures.forEach(t => (t.needsUpdate = false))
        if (Array.isArray(out) || out === undefined) {
            const textures = Array.isArray(out) ? out : this.o;
            this.drawerAll.fragment(generateDynamicShader(this.precision, textures.length)).out();
            this.drawerAll.uniforms = textures.map((t, i) => ({
                name: "o" + i,
                type: "sampler2D",
                value: t,
            }));
            this.drawerAll.activate(true, true);
        }
        else {
            this.drawer.uniforms = [{ name: "tex", type: "sampler2D", value: out }];
            this.drawer.activate(true, true);
        }
        this.renderOutput = out;
        return this;
    }
    hush() {
        this.s.forEach(s => s.clear());
        this.o.forEach(o => o.clear());
        this.seq.forEach(seq => seq.stop());
        for (let i = 0; i < this.shaders.length; i++) {
            this.shaders[i].clear();
            delete this.shaders[i];
        }
        this.shaders = [];
        for (let i = 0; i < this.textures.length; i++) {
            this.textures[i].clear();
            delete this.textures[i];
        }
        this.textures = [];
        this.defaultOutput = this.o[0];
        this.render(this.o[0]);
        this.sandbox.varyng("update", null);
        this.speed = 1;
    }
    stop() {
        this.hush();
        this.tickItv && cancelAnimationFrame(this.tickItv);
    }
    resize(width, height) {
        width = Math.round(width);
        height = Math.round(height);
        this.export("width", width);
        this.export("height", height);
        this.export("ratio", width / height);
        this.width = width;
        this.height = height;
        this.o.forEach(o => o.resize(width, height));
        this.s.forEach(s => s.resize(width, height));
        this.shaders.forEach(s => s.resize(width, height));
        Object.keys(this.modules).forEach(key => this.modules[key].resize(width, height));
        this.renderer.resize(width, height);
        this._camera.resize(width, height);
    }
    tick(dt = (1000 / 60) * 0.001) {
        this.speed = this.sandbox.varyng("speed");
        this.bpm = this.sandbox.varyng("bpm");
        this.time += dt * this.speed;
        this.deltaTime = dt * this.speed;
        this.export("time", this.time);
        this.export("deltaTime", this.deltaTime);
        this.export("mouseX", this.mouseX);
        this.export("mouseY", this.mouseY);
        this._camera.update();
        // call update function
        if (this.sandbox.varyng("update", () => { })(dt) === false)
            return;
        this.shaders.forEach(s => s.update());
        this.o.forEach(o => o.update());
        this.s.forEach(s => s.update());
        this.seq.forEach(seq => seq.update(dt * this.speed));
        Object.keys(this.modules).forEach(key => this.modules[key].update());
    }
    start() {
        this.tickItv && cancelAnimationFrame(this.tickItv);
        this.tickItv = requestAnimationFrame(this.autoTick);
    }
    autoTick(now) {
        now *= 0.001;
        const dt = now - this.lastTime;
        this.lastTime = now;
        this.tick(dt);
        //console.log("tick", Math.round(1 / dt))
        this.tickItv = requestAnimationFrame(this.autoTick);
    }
    eval(code, fullCode = true) {
        let transpiled = code;
        // try {
        // 	transpiled = transpiled
        // 		.split("\n\n")
        // 		.map(c => {
        // 			//console.log("c", c.replaceAll("\n", ""))
        // 			//console.log(transpile(c.replaceAll("\n", "")))
        // 			return transpile(c)
        // 		})
        // 		.join("\n\n")
        // } catch (e) {
        // 	console.log("e", e)
        // 	return Promise.resolve(e)
        // }
        //console.log(transpiled)
        this.sandbox.varyng("update", null);
        return new Promise(resolve => {
            this.sandbox
                .eval(transpiled)
                .then(e => {
                if (!e) {
                    this.render(this.renderOutput);
                    this.source = transpiled;
                    resolve(false); // no error
                }
                else {
                    console.log(e);
                    resolve(e);
                }
            })
                .catch(e => {
                console.log(e);
                resolve(e);
            });
        });
    }
    loadScript(url, module = false) {
        if (!url || document.querySelector(`script[src="${url}"]`)) {
            console.log(`script ${url} already loaded`);
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.setAttribute("crossorigin", "anonymous");
            if (module) {
                script.innerHTML = `
					import Module from "${url}"
					console.log([Module])
					Object.keys(Module).forEach(key => {
						window[key] = Module[key]
					})
				`;
                script.setAttribute("type", "module");
                document.head.append(script);
                console.log(`script module ${url} loaded`);
                resolve();
            }
            else {
                script.addEventListener("load", () => {
                    console.log(`script ${url} loaded`);
                    resolve();
                });
                script.addEventListener("error", () => reject());
                script.src = url;
                document.head.append(script);
            }
        });
    }
    //// Modules
    module(name) {
        if (!this.modules[name])
            this.modules[name] = new Module(this, name);
        const module = this.modules[name];
        if (module)
            this.export(name, module);
        else
            this.sandbox.remove(name);
        return module;
    }
    include(...requires) {
        return this.require(...requires);
    }
    require(...requires) {
        const generator = {
            functions: [],
            defines: [],
            utilities: [],
        };
        requires.forEach(require => {
            let index = glsl_functions.findIndex(({ name }) => name === require);
            if (index >= 0) {
                if (!generator.functions.includes(require)) {
                    const fn = glsl_functions[index];
                    generator.functions.push(fn.name);
                    if (fn.defines)
                        generator.defines.push(...fn.defines);
                }
            }
            else if (typeof require === "string" && require in DEFINES) {
                if (!generator.defines.includes(require))
                    generator.defines.push(require);
            }
            else {
                if (!generator.utilities.includes(require))
                    generator.utilities.push(require);
            }
        });
        return Generate.generator(generator);
    }
    debugger(container) {
        if (!container) {
            if (this.debuggerItv) {
                clearInterval(this.debuggerItv);
                this.debuggerItv = null;
            }
            return;
        }
        const v = (n, list) => Array.isArray(n)
            ? list
                ? n
                    .map(v)
                    .map(e => `<li>${e}</li>`)
                    .join("")
                : `[${n.map(v).join(", ")}]`
            : `${typeof n === "number" ? n.toFixed(2) : n}`;
        const updates = [];
        const ob = (name, list = false) => {
            updates.push(obj => (element.querySelector(`#gy_d_${name}`).innerHTML = v(obj[name], list)));
            return list ? `<ul id="gy_d_${name}"></ul>` : `<span id="gy_d_${name}"></span>`;
        };
        const element = document.createElement("div");
        element.innerHTML = `
		<div id="gy_d_time">time: ${ob("time")}</div>
		<div>fps: <span id="gy_d_fps">${ob("fps")}</span></div>
		<div id="gy_d_mouse">mouse: ${ob("mouse")}</div>
		<div id="gy_d_speed">speed: ${ob("speed")}</div>
		<div>
			Shaders:
			${ob("shaders", true)}
		</div>
		<div>
			Textures:
			${ob("textures", true)}
		</div>
	`;
        const update = () => {
            const obj = {
                time: this.time,
                fps: 1 / this.deltaTime,
                mouse: [this.mouseX, this.mouseY],
                speed: this.speed,
                shaders: this.shaders.map(s => s.name + " " + s.drawCalls),
                textures: this.textures.map(t => t.name + " " + t.updates),
            };
            updates.forEach(fn => fn(obj));
            this.debuggerItv = requestAnimationFrame(update);
        };
        container.innerHTML = "";
        container.append(element);
        this.debuggerItv = requestAnimationFrame(update);
    }
}
function generateDynamicShader(precision, numTextures) {
    const [columns, rows] = gridSize(numTextures);
    let shaderSource = `#version 300 es
	
	  precision ${precision} float;

	  in vec3 vPosition;
	`;
    for (let i = 0; i < numTextures; i++) {
        shaderSource += `
		uniform sampler2D o${i};`;
    }
    const f = 6;
    shaderSource += `
		out vec4 fragColor;
		
		void main() {
			vec2 st = vPosition.xy;
			//st.y = 1. - st.y;
			vec2 texSize = vec2(1.0 / ${columns.toFixed(f)}, 1.0 / ${rows.toFixed(f)});
			vec2 texCoord = floor(st / texSize);
	
			// Calculate the index based on the texture coordinates
			float index = texCoord.x + texCoord.y * ${columns.toFixed(f)};
			index = mod(index, float(${numTextures.toFixed(f)}));

			// Calculate the row and column indices
			int rowIndex = int(index / ${columns.toFixed(f)});
			int colIndex = int(mod(index, ${columns.toFixed(f)}));

			// Calculate the UV coordinates for the selected texture
			vec2 uvCoord = (st - vec2(texSize.x * float(colIndex), texSize.y * float(rowIndex))) / texSize;
			
			// Assign textures based on index
			${Array.from({ length: numTextures }, (_, i) => `
			if (index == ${i}.0) {
				fragColor = texture(o${i}, uvCoord);
			}`).join("\n")}
		}
	`;
    return shaderSource;
}
export default Synth;
