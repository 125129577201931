import Output from "../output";
import Generate from "../shader/generator";
import Shader from "../shader/shader";
import Texture from "../texture";
import { glsl_functions } from "./functions";
import DEFINES from "./utilities/defines";
export const glslIds = {};
class GLSLSource {
    constructor(synth, glslFunction, args = [], parent) {
        this.glsl = "";
        this.uniforms = [
            {
                name: "time",
                type: "float",
                value: () => this.synth.time,
            },
            {
                name: "resolution",
                type: "vec2",
                value: () => [this.synth.width, this.synth.height],
            },
            {
                name: "mouse",
                type: "vec2",
                value: () => [this.synth.mouseX, this.synth.mouseY],
            },
        ];
        glslIds[glslFunction.name] = (glslIds[glslFunction.name] || 0) + 1;
        this.id = `${parent ? parent.fn.name + "_" : ""}${glslFunction.name}${glslIds[glslFunction.name]}`;
        this.fn = glslFunction;
        this.synth = synth;
        this.name = glslFunction.name;
        this.type = glslFunction.type;
        this.codeType = glslFunction.type === "code" ? glslFunction.codeType : null;
        this.glsl = glslFunction.type === "code" ? "" : glslFunction.glsl;
        args = "transform" in glslFunction ? glslFunction.transform(...args) : args;
        this.inputs =
            (glslFunction.type === "code"
                ? Generate.sanitizeInput(args.slice(this.codeType === "combineCoord" || this.codeType === "combine" ? 2 : 1).length > 0
                    ? args.slice(this.codeType === "combineCoord" || this.codeType === "combine" ? 2 : 1).reduce((acc, cur, index) => {
                        acc["t" + index] = cur;
                        return acc;
                    }, {})
                    : {})
                : Generate.sanitizeInput(glslFunction.inputs)) || [];
        this.utilities = glslFunction.require || [];
        this.defines = glslFunction.defines || [];
        this.functions = [];
        this.varyings = [];
        this.args = args;
        this.parent = parent;
    }
    out(output = this.synth.defaultOutput) {
        if (output instanceof Output) {
            this.output = output;
            this.output.generate(this.generator());
        }
        else {
            if (output instanceof Texture) {
                // one time rendering to texture
                const shader = new Shader(this.synth, {
                    fragment: this.generator(),
                    ...Shader.defaultGydraShader,
                });
                shader.out(output);
            }
            else {
                console.warn("Invalid output", output);
            }
        }
    }
    generator() {
        const root = this.getRoot();
        return root.generate();
    }
    getRoot() {
        return this.parent ? this.parent.getRoot() : this;
    }
    generate(inValue = v => v) {
        let result = {
            uniforms: this.uniforms,
            utilities: this.utilities,
            functions: [...this.functions, this.name],
            defines: this.defines,
            varyings: this.varyings,
            code: [],
            value: inValue,
        };
        let name = this.name;
        let type = this.type;
        let t_args = this.args;
        if (this.type === "code") {
            const code = Generate.sanitizeFunctionCode(this.args[0]);
            t_args = this.args.slice(1);
            result.functions = [...this.functions];
            result.code = [
                {
                    type: this.codeType,
                    name: this.id,
                    source: code,
                    inputs: this.inputs,
                },
            ];
            name = this.id;
            type = this.codeType;
        }
        switch (type) {
            case "src": {
                if (this.parent) {
                    //console.log("1 parent", this.parent.name, this.name)
                    const parentRoot = this.getRoot();
                    this.parent.child = null;
                    this.parent = null;
                    const toChild = new GLSLSource(this.synth, this.fn, [parentRoot, ...t_args]);
                    //console.log("2", this.child)
                    toChild.id = this.id;
                    if (this.child) {
                        this.child.parent = toChild;
                        toChild.child = this.child;
                    }
                    //console.log("3", toChild, toChild.generate(inValue))
                    return toChild.generate(inValue);
                }
                const args = GLSLSource.resolveArguments(this.synth, this.id, result, t_args, this.inputs);
                const argStr = args.length ? ", " + args.join(", ") : "";
                result.value = (st) => `${name}(${st}${argStr})`;
                break;
            }
            case "coord": {
                const args = GLSLSource.resolveArguments(this.synth, this.id, result, t_args, this.inputs);
                const argStr = args.length ? ", " + args.join(", ") : "";
                result.value = (st) => inValue(`${name}(${st}${argStr})`);
                break;
            }
            case "color": {
                const args = GLSLSource.resolveArguments(this.synth, this.id, result, t_args, this.inputs);
                const argStr = args.length ? ", " + args.join(", ") : "";
                result.value = (st) => `${name}(${inValue(st)}${argStr})`;
                break;
            }
            case "combine": {
                const to = GLSLSource.resolve(this.synth, t_args[0], inValue);
                Generate.merge(result, to);
                const args = GLSLSource.resolveArguments(this.synth, this.id, result, t_args.slice(1), this.inputs);
                const argStr = args.length ? ", " + args.join(", ") : "";
                result.value = (st) => `${name}(${inValue(st)}, ${to.value(st)}${argStr})`;
                break;
            }
            case "combineCoord": {
                const to = GLSLSource.resolve(this.synth, t_args[0], inValue);
                Generate.merge(result, to);
                const args = GLSLSource.resolveArguments(this.synth, this.id, result, t_args.slice(1), this.inputs);
                const argStr = args.length ? ", " + args.join(", ") : "";
                result.value = (st) => inValue(`${name}(${st}, ${to.value(st)}${argStr})`);
                break;
            }
        }
        if (this.child) {
            const child = this.child.generate(result.value);
            //console.log("this.child", this.child)
            //console.log("result", result)
            //console.log("child", child)
            //if (this.child) {
            Generate.merge(result, child);
            //}
            result.value = child.value;
        }
        return result;
    }
    static resolve(synth, arg, inValue) {
        switch (true) {
            case arg instanceof Shader:
                if (!arg.texture)
                    arg.out(new Texture(synth));
                arg = arg.texture;
            case arg instanceof Texture:
                arg = arg.toGLSLSource();
            case arg instanceof GLSLSource:
                return arg.getRoot().generate(inValue);
        }
        // raw source code
        if (typeof arg === "string")
            return { value: () => arg };
        // maybe will not work, but is a error case
        console.log("GLSLSource resolve warn", [arg], "is not type Shader | Texture | GLSLSource");
        return { value: inValue };
    }
    static resolveArguments(synth, id, result, args, defaultsValues) {
        const resolved = defaultsValues.map(({ name, type, default: _default }, i) => {
            let value = typeof args[i] === "undefined" ? _default : args[i];
            if (value instanceof Shader) {
                const uniformName = `S${id}_${value.name}`;
                if (typeof result.uniforms.find(({ name }) => name === uniformName) === "undefined") {
                    if (!value.texture)
                        value.out(new Texture(synth, { name: uniformName }));
                    result.uniforms.push({
                        type: "sampler2D",
                        name: uniformName,
                        value: value.texture,
                    });
                }
                return uniformName;
            }
            if (value instanceof GLSLSource) {
                const uniformName = `G${id}_${value.name}`;
                if (typeof result.uniforms.find(({ name }) => name === uniformName) === "undefined") {
                    const shader = new Shader(synth, {
                        precision: synth.precision,
                        name: uniformName,
                        ...Shader.defaultGydraShader,
                    }).fragment(value.getRoot().generate());
                    shader.out(new Texture(synth, { name: uniformName }));
                    result.uniforms.push({
                        type: "sampler2D",
                        name: uniformName,
                        value: shader.texture,
                    });
                }
                return uniformName;
            }
            if (value instanceof Texture) {
                const uniformName = `T${id}_${value.name}`;
                if (!result.uniforms.find(({ name }) => name === uniformName)) {
                    result.uniforms.push({
                        type: "sampler2D",
                        name: uniformName,
                        value,
                    });
                }
                return uniformName;
            }
            if (typeof _default !== typeof value) {
                if (typeof value === "string") {
                    // example shape(4, 'noise3d(vec3(time, 0.0, 0.0))').require('noise3d').out()
                    return value;
                }
                else {
                    const uniformName = `U${id}_${name}_${i}`;
                    result.uniforms.push({
                        type: type,
                        name: uniformName,
                        value,
                    });
                    return uniformName;
                }
            }
            else {
                if (typeof value === "number") {
                    return type === "int" ? Math.floor(value) : Generate.numberFormatter(value);
                }
                else if (Array.isArray(value)) {
                    if (/float\[\d+\]/.test(type)) {
                        return `float[${value.length}](${value.map(d => Generate.numberFormatter(d)).join(", ")})`;
                    }
                    return `vec${value.length}(${value
                        .map((d, i) => (type === "int" ? Math.floor(d) : Generate.numberFormatter(d)))
                        .join(", ")})`;
                }
            }
            console.warn(`Invalid argument value ${value} for argument ${name}`);
            return _default;
        });
        return resolved;
    }
    require(...requires) {
        requires.forEach(require => {
            let index = glsl_functions.findIndex(({ name }) => name === require);
            if (index >= 0) {
                if (!this.functions.includes(require)) {
                    const fn = glsl_functions[index];
                    this.functions.push(fn.name);
                    if (fn.defines)
                        this.defines.push(...fn.defines);
                }
            }
            else if (typeof require === "string" && require in DEFINES) {
                if (!this.defines.includes(require))
                    this.defines.push(require);
            }
            else {
                if (!this.utilities.includes(require))
                    this.utilities.push(require);
            }
        });
        return this;
    }
    uniform(toAdd) {
        const uniforms = Generate.sanitizeUniforms(toAdd);
        uniforms.forEach(uniform => {
            if (typeof this.uniforms.find(({ name }) => uniform.name === name) === "undefined")
                this.uniforms.push(uniform);
        });
        return this;
    }
}
glsl_functions.map(func => {
    if (func.type.indexOf("sd") === 0)
        return;
    GLSLSource.prototype[func.name] = function (...args) {
        const p = this;
        return (p.child = new GLSLSource(p.synth, func, args, p));
    };
});
export { GLSLSource };
