import Buffer, { GL_ARRAY_BUFFER, GL_ELEMENT_ARRAY_BUFFER, GL_FLOAT, GL_UNSIGNED_BYTE } from "./buffer";
import { GL_POINTS } from "./shader";
let v = 1;
export default class VAO {
    constructor(synth, props = {}) {
        this.synth = synth;
        this.name = props.name || "vao_" + v++;
        this.attributes = {};
        if (props.attrs)
            this.attrs(props.attrs);
        if (props.cells)
            this.cells(props.cells);
        this._elements = props.elements;
        this._count = props.count;
        this._rasterizerDiscard = props.rasterizerDiscard ?? false;
        this._primitive = props.primitive ?? GL_POINTS;
        this.buffer = this.synth.renderer.gl.createVertexArray();
    }
    //////////////////////
    bindBuffers() {
        const gl = this.synth.renderer.gl;
        if (this.buffer)
            gl.deleteVertexArray(this.buffer);
        this.buffer = gl.createVertexArray();
        gl.bindVertexArray(this.buffer);
        for (const [_, b] of Object.entries(this.attributes)) {
            gl.bindBuffer(b._target, b._buffer);
            gl.bufferData(b._target, b._data, b._usage);
        }
        if (this.indices) {
            gl.bindBuffer(this.indices._target, this.indices._buffer);
            gl.bufferData(this.indices._target, this.indices._data, this.indices._usage);
        }
        gl.bindVertexArray(null);
        return this;
    }
    //////////////////////
    attrs(attrs) {
        for (const [name, attr] of Object.entries(attrs)) {
            if (this.attributes[name])
                this.attributes[name].destroy();
            this.attributes[name] = attr instanceof Buffer ? attr : new Buffer(this.synth, attr);
        }
        return this.bindBuffers();
    }
    cells(cells) {
        this.indices =
            cells instanceof Buffer
                ? cells
                : new Buffer(this.synth, {
                    ...(Array.isArray(cells) ? { data: Uint16Array.from(cells) } : cells),
                    target: GL_ELEMENT_ARRAY_BUFFER,
                });
        return this.bindBuffers();
    }
    elements(elements) {
        this._elements = elements;
        return this;
    }
    count(count) {
        this._count = count;
        return this;
    }
    primitive(primitive) {
        this._primitive = primitive;
        return this;
    }
    rasterizerDiscard(rasterizerDiscard = true) {
        this._rasterizerDiscard = rasterizerDiscard;
        return this;
    }
    instances(instances = 0) {
        this._instances = instances;
        if (this._instances > 0) {
            this.attrs({
                instance: {
                    data: new Uint8Array([...Array(instances).keys()]),
                    size: 1,
                    divisor: 1,
                    type: GL_UNSIGNED_BYTE,
                    target: GL_ARRAY_BUFFER,
                },
                instanceOffset: {
                    data: new Float32Array([...Array(instances).keys()].map(i => i / instances)),
                    size: 1,
                    divisor: 1,
                    type: GL_FLOAT,
                    target: GL_ARRAY_BUFFER,
                },
            });
        }
        else {
            delete this.attributes["instance"];
            delete this.attributes["instanceOffset"];
        }
        return this;
    }
    //////////////////////
    bindVertexAttrs(shader) {
        if (!shader || !shader.program) {
            console.warn("shader not found or not compiled");
            return this;
        }
        const gl = this.synth.renderer.gl;
        gl.bindVertexArray(this.buffer);
        for (let name in this.attributes) {
            const attr = this.attributes[name];
            const location = gl.getAttribLocation(shader.program, name);
            if (location !== -1) {
                gl.bindBuffer(attr._target, attr._buffer);
                gl.enableVertexAttribArray(location);
                gl.vertexAttribPointer(location, attr._size, attr._type, false, 0, 0);
                gl.vertexAttribDivisor(location, attr._divisor);
            }
            else {
                console.warn(`location ${name} not found`);
            }
        }
        gl.bindVertexArray(null);
        return this;
    }
    clone() {
        const vao = new VAO(this.synth, {
            name: this.name + "_cloned",
            attrs: this.attributes,
            cells: this.indices,
            elements: this._elements,
            count: this._count,
            primitive: this._primitive,
            instances: this._instances,
            rasterizerDiscard: this._rasterizerDiscard,
        });
        return vao;
    }
}
