import { getArrayValue } from "../array";
import { glsl_functions, glsl_utilities } from "../glsl";
import { GLSL_ARG, GLSL_TYPE_LOOKUP, SIZE_TO_TYPE } from "../glsl/typeMapping";
import DEFINES from "../glsl/utilities/defines";
import Texture from "../texture";
import { trimSource } from "../utils";
import { GL_FRAGMENT_SHADER, GL_VERTEX_SHADER, ShaderVaryingsMode, } from "./shader";
const Generate = {
    version: "300 es",
    function(type, name, inputs, source = "", onlyDeclaration = false) {
        const fnArgs = Generate.sanitizeInput(inputs).map(({ name, type }) => `${type} ${name}`);
        const declaration = `${GLSL_TYPE_LOOKUP[type].returnType} ${name}(${[
            ...GLSL_TYPE_LOOKUP[type].args,
            ...fnArgs,
        ].join(", ")})`;
        return onlyDeclaration ? declaration : `${declaration}{\n\t${trimSource(source)}\n}`;
    },
    fragment(precision, generator, shaderUniforms = [], shaderDefines = {}, shaderVaryings = {}) {
        generator = { ...generator };
        if (!generator.code)
            generator.code = [];
        generator.code = generator.code.filter(c => c.name !== "generate");
        generator.code.push({
            type: "src",
            inputs: [],
            name: "generate",
            source: `return ${generator && generator.value ? generator.value(GLSL_ARG.st) : `vec4(${GLSL_ARG.st}, 1.)`};`,
        });
        return Generate.glsl(GL_FRAGMENT_SHADER, precision, generator, shaderUniforms, shaderDefines, shaderVaryings, `		
				vec3 ${GLSL_ARG.st} = vec3(gl_FragCoord.xy, .0);
				#ifdef HAS_POSITION
				${GLSL_ARG.st} = vPosition;
				#endif
				#ifdef HAS_TEXCOORD
				${GLSL_ARG.st} = vec3(vTexcoord, 0.0);
				#endif
				vec2 uv = ${GLSL_ARG.st}.xy;
				
				vec4 _color = vec4(1.0);
				#ifdef HAS_VERTEXCOLOR
				_color = vVertexcolor;
				#endif
				_color = generate(${GLSL_ARG.st});
				fragColor = _color;
			`);
    },
    vertex(precision, generator, shaderUniforms = [], shaderDefines = {}, shaderVaryings = {}) {
        generator = { ...generator };
        if (!generator.code)
            generator.code = [];
        generator.code = generator.code.filter(c => c.name !== "generate");
        generator.code.push({
            type: "src",
            inputs: [],
            name: "generate",
            source: `return ${generator && generator.value ? generator.value(GLSL_ARG.st) : `vec4(${GLSL_ARG.st}, 1.)`};`,
        });
        return Generate.glsl(GL_VERTEX_SHADER, precision, generator, shaderUniforms, shaderDefines, shaderVaryings, `	
				mat4 modelViewMatrix = mat4(
					1.0f, 0.0f, 0.0f, 0.0f,
					0.0f, 1.0f, 0.0f, 0.0f,
					0.0f, 0.0f, 1.0f, 0.0f,
					0.0f, 0.0f, 0.0f, 1.0f
				);

				#ifdef HAS_CAMERA
				modelViewMatrix *= viewMatrix;
				#endif

				#ifdef HAS_MODEL_MATRIX
				modelViewMatrix *= modelMatrix;
				#endif

				#ifdef HAS_NORMAL
				vNormal = transpose(inverse(mat3(modelViewMatrix))) * generate(normal).xyz;
				#endif
				#ifdef HAS_TANGENT
				vTangent = generate(tangent.xyz); 
				#endif

				vec4 generatedPosition = generate(position);
				vPosition = generatedPosition.xyz * 0.5 + 0.5;
				#ifdef HAS_CAMERA
				gl_Position = projectionMatrix * modelViewMatrix * generatedPosition;
				#else
				gl_Position = modelViewMatrix * generatedPosition;
				#endif

				#ifdef HAS_TEXCOORD
				vTexcoord = texCoord;
				#endif
	
				#ifdef HAS_VERTEXCOLOR
				vVertexcolor = vertexColor;
				#endif

				#ifdef HAS_INSTANCE
				vInstance = instance;
				#endif

				#ifdef HAS_POINTSIZE
					gl_PointSize = pointSize;
				#endif
			`);
    },
    generator(generator, shader, shaderUniforms = [], shaderDefines = {}, shaderVaryings = {}) {
        const defines = [...(generator.defines || [])]
            .map(define => (DEFINES[define] ? `#define ${define} ${DEFINES[define]}` : define))
            .concat(Object.keys(shaderDefines).map(key => `#define ${key} ${typeof shaderDefines[key] === "boolean" ? (shaderDefines[key] ? 1 : 0) : shaderDefines[key]}`));
        const structs = [];
        const uniformsDefinitions = [...(generator.uniforms || []), ...shaderUniforms]
            .filter((uniform, index, self) => self.findIndex(u => u.name === uniform.name) === index)
            .map(uniform => `uniform ${uniform.type} ${uniform.name}`)
            .sort((a, b) => a.localeCompare(b));
        const varyingsDefitions = [
            ...(generator.varyings || []),
            ...(shader ? Generate.varyngs(shader, shaderVaryings) : []),
        ].sort((a, b) => a.localeCompare(b));
        const utilities = [...(generator.utilities || [])].map(utility => glsl_utilities[utility] ?? utility);
        const fns = [...(generator.functions || [])].map(fnNameOrFn => {
            const f = (typeof fnNameOrFn === "string"
                ? glsl_functions.find(f => f.name === fnNameOrFn && f.type !== "code")
                : fnNameOrFn);
            return Generate.function(f.type, f.name, f.inputs, f.glsl);
        });
        /*const fnsDeclarations = [...(generator.functions || [])].map(fnNameOrFn => {
            const f = (
                typeof fnNameOrFn === "string"
                    ? glsl_functions.find(f => f.name === fnNameOrFn && f.type !== "code")
                    : fnNameOrFn
            ) as GLSLFunctionSource

            return Generate.function(f.type, f.name, f.inputs, null, true)
        })*/
        const codes = [...(generator.code || [])].map(code => Generate.function(code.type, code.name, code.inputs, code.source));
        const functions = [...utilities, ...fns, ...codes];
        const join = (arr, s = "") => arr.join(s + "\n") + (arr.length > 0 ? s : "") + "\n";
        return [
            join(defines),
            join(uniformsDefinitions, ";"),
            join(structs, ";"),
            join(varyingsDefitions, ";"),
            //join(fnsDeclarations, ";"),
            join(functions),
        ]
            .join("\n")
            .replaceAll(/\n\n\n/g, "\n\n");
    },
    glsl(shader, precision, generator, shaderUniforms = [], shaderDefines = {}, shaderVaryings = {}, body) {
        const source = Generate.generator(generator, shader, shaderUniforms, shaderDefines, shaderVaryings);
        return [
            `#version ${Generate.version}`,
            `precision ${precision} float;`,
            source,
            "void main() {",
            body.trim().replaceAll(/\t/g, "").replaceAll(/\n/g, "\n\t"),
            "}",
        ]
            .join("\n")
            .replaceAll(/\n\n\n/g, "\n\n");
    },
    uniforms(synth, uniforms = {}) {
        if (!Array.isArray(uniforms))
            return uniforms; // already ShaderUniforms
        const result = {};
        for (let i = 0, len = uniforms.length; i < len; i++) {
            const uniform = uniforms[i];
            //if (typeof uniform.value !== "undefined") {
            let value = typeof uniform.value === "function" ? uniform.value(synth) : uniform.value;
            if (value instanceof Texture) {
                // se decommento non funziona la sequence, probabilemente bisogna attivare la texture
                // value.next()
                value = value.texture();
            }
            else {
                // let realType = Generate.toUniformType(value)
                // TODO: remove type 'synth' from Uniforms['type'], to test
                //if (realType !== -1 && realType !== uniform.type && Array.isArray(value)) {
                if (uniform.type === "float" && Array.isArray(value)) {
                    value = getArrayValue(value, {
                        time: synth.time,
                        bpm: synth.bpm,
                    });
                }
                //}
            }
            result[uniform.name] = [uniform.type, value];
        }
        // console.log("Generate.uniforms", uniforms, result)
        return result;
    },
    varyngs(shaderType, varyings) {
        const result = [];
        Object.keys(varyings).forEach(name => {
            const { type, shader, mode } = varyings[name];
            if (typeof shader !== "undefined" && shaderType !== shader)
                return;
            switch (true) {
                case mode === ShaderVaryingsMode.IN ||
                    (mode === ShaderVaryingsMode.SHARED && shaderType === GL_FRAGMENT_SHADER):
                    result.push(`in ${type} ${name}`);
                    break;
                case mode === ShaderVaryingsMode.OUT || (mode === ShaderVaryingsMode.SHARED && shaderType === GL_VERTEX_SHADER):
                    result.push(`out ${type} ${name}`);
                    break;
            }
        });
        return result;
    },
    merge(source, toAdd) {
        if (!source.uniforms)
            source.uniforms = [];
        if (!source.functions)
            source.functions = [];
        if (!source.utilities)
            source.utilities = [];
        if (!source.varyings)
            source.varyings = [];
        if (!source.code)
            source.code = [];
        if (!source.defines)
            source.defines = [];
        (toAdd.uniforms || []).forEach(uniform => {
            const existing = source.uniforms.find(({ name }) => name === uniform.name);
            if (!existing)
                source.uniforms.push(uniform);
        });
        (toAdd.functions || []).forEach(fn => {
            if (!source.functions.includes(fn))
                source.functions.push(fn);
        });
        (toAdd.utilities || []).forEach(fn => {
            if (!source.utilities.includes(fn))
                source.utilities.push(fn);
        });
        (toAdd.code || []).forEach(fn => {
            if (!source.code.find(c => c.name === fn.name))
                source.code.push(fn);
        });
        (toAdd.defines || []).forEach(define => {
            if (!source.defines.includes(define))
                source.defines.push(define);
        });
        (toAdd.varyings || []).forEach(varyng => {
            if (!source.varyings.includes(varyng))
                source.varyings.push(varyng);
        });
    },
    toUniformType(value) {
        if (typeof value === "function")
            value = value();
        if (Number.isNaN(value) ||
            value instanceof WebGLTexture ||
            value instanceof WebGLFramebuffer ||
            value instanceof Texture ||
            (typeof value === "number" && isNaN(value)))
            return "sampler2D";
        if (typeof value === "number")
            return "float";
        if (Array.isArray(value) || value instanceof Float32Array) {
            if (typeof value[0] === "number") {
                if (value.length in SIZE_TO_TYPE)
                    return SIZE_TO_TYPE[value.length];
                return `float[${value.length}]`;
            }
            // why this ???
            // if (value[0] instanceof WebGLTexture) return `sampler2D`
        }
        //throw new Error(`Uniform type not supported for value "${value}(${typeof value})"`)
        console.warn(`Uniform type not supported for value "${value}(${typeof value})"`, value);
        return -1;
    },
    sanitizeInput(input) {
        if (!input)
            return [];
        return !Array.isArray(input)
            ? Object.entries(input).map(([name, value]) => {
                const type = Generate.toUniformType(value);
                if (type === -1) {
                    console.warn("Generate.sanitizeInput", name, value, type);
                    throw new Error(`Uniform '${name}' is not supported`);
                }
                return {
                    name,
                    default: value,
                    type: type,
                };
            })
            : input;
    },
    sanitizeUniforms(uniforms) {
        const result = [];
        if (Array.isArray(uniforms)) {
            return uniforms
                .map(uniform => ({
                name: uniform.name,
                value: uniform.value || uniform.default,
                type: uniform.type,
            }))
                .filter(({ value }) => value !== null);
        }
        if (typeof uniforms === "object") {
            Object.entries(uniforms).forEach(([name, value]) => {
                if (value !== null) {
                    const type = Generate.toUniformType(value);
                    if (type !== -1) {
                        result.push({
                            name,
                            value: value,
                            type: type,
                        });
                    }
                }
            });
        }
        return result.filter(({ value }) => value !== null);
    },
    sanitizeShaderString(str, precition) {
        // chck str has main
        if (!str.includes("main"))
            str = `void main(){\n${Generate.sanitizeFunctionCode(str, "")}\n}`;
        // check str init with #version
        if (!str.trim().startsWith("#version"))
            str = `#version ${Generate.version}\n${str}`;
        // check str has precision after #version
        if (!str.trim().startsWith(`#version ${Generate.version}\nprecision ${precition} float;`))
            str = str.replace(`#version ${Generate.version}`, `#version ${Generate.version}\nprecision ${precition} float;`);
        return str;
    },
    sanitizeFunctionCode(code, finalAssign = "return") {
        if (code.length < 1)
            return code;
        // trim emspty lines
        code = code.trim();
        // trim empty \n
        code = code.replaceAll("\n\n", "\n");
        // get final line
        const lines = code.split("\n");
        const ll = lines.length - 1;
        let lastLine = lines[ll].trim();
        // remove last line if empty
        if (!lastLine) {
            lines.pop();
            lastLine = lines[ll - 1].trim();
        }
        // check if last line is a return
        if (!code.includes(finalAssign) && lastLine.substring(0, 6) !== finalAssign) {
            // add return
            lines[ll] = finalAssign + " " + lastLine;
        }
        code = lines.join("\n");
        // add ; if not present
        if (code.substring(code.length - 1) != ";")
            code += ";";
        return code;
    },
    numberFormatter(value) {
        return Number.isInteger(value) ? value + "." : value.toString();
    },
};
export default Generate;
