import Gydra from "gydra-synth/dist/index"

import video1 from "url:./video/1.mp4"
import video2 from "url:./video/2.mp4"
import video3 from "url:./video/3.mp4"
import video4 from "url:./video/4.mp4"
import video5 from "url:./video/5.mp4"
import video6 from "url:./video/6.mp4"
import video7 from "url:./video/7.mp4"
import video8 from "url:./video/8.mp4"

const videos = [video1, video2, video3, video4, video5, video6, video7, video8]

const ratio = 16 / 9

const canvas = document.createElement("canvas")
canvas.width = window.innerWidth
canvas.height = window.innerHeight

//@ts-ignore
const gydra = Gydra({
	useWorker: false,
	canvas,
	sources: 20,
	makeGlobal: true,
	autoResize: true,
	debug: false,
	outputs: 8,
})

const code = `
	videos = ${JSON.stringify(videos)}

	for (let i = 0; i < videos.length; i++) {
		await synth.s[i].initVideo(videos[i])
	}
    
    load = s => src(s).mask(shape(4, 1)).scale(1, 1,-1).scale(1, 1 / ratio * s.ratio)

    r = solid()
    if (width > height) {
        videos.forEach((v, i) => {
            r = r.layer(load(synth.s[i]).scrollX(ratio * (i / videos.length), .1))
        })
    } else {
        r = r.layer(load(synth.s[Math.floor(Math.random() * videos.length)]))
        
    }

    r
    .colorama(() => Math.random() * 0.000001)
    .out(o0)
    render(o0)
`

export default () => {
	return {
		render: () => {
			gydra.eval(code)
		},
		getCanvas: () => canvas,
	}
}

document.body.appendChild(canvas)
