import { version } from "../package.json";
import "./array";
import Gydra from "./gydra";
export * from "./module";
export * from "./output";
export * from "./renderer";
export * from "./sandbox";
export * from "./sequence";
export * from "./shader/shader";
export * from "./source";
export * from "./synth";
export * from "./texture";
export * from "./utils";
const GydraFn = (props = {}) => new Gydra(props);
GydraFn.functions = Gydra.functions;
GydraFn.version = version;
export default GydraFn;
