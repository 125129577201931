import Buffer, { GL_SEPARATE_ATTRIBS } from "./shader/buffer";
import { imageToCanvas } from "./utils";
const defaultSettings = {
    debug: true,
};
const FRAMEBUFFER_MESSAGES = {
    36054: "FRAMEBUFFER_INCOMPLETE_ATTACHMENT",
    36055: "FRAMEBUFFER_INCOMPLETE_MISSING_ATTACHMENT",
    36057: "FRAMEBUFFER_INCOMPLETE_DIMENSIONS",
    36061: "FRAMEBUFFER_UNSUPPORTED",
    36063: "FRAMEBUFFER_INCOMPLETE_MULTISAMPLE",
};
export default class Renderer {
    constructor(settings) {
        settings = { ...defaultSettings, ...settings };
        this.debug = settings.debug;
        this.settings = settings;
        this.setCanvas(settings.canvas);
    }
    setCanvas(canvas) {
        this.canvas = canvas;
        if (!(this.canvas instanceof OffscreenCanvas)) {
            this.canvas.setAttribute("data-id", this.settings.id);
        }
        const settings = {
            alpha: true,
            // antialias: true,
            // depth: true,
            desynchronized: true,
            powerPreference: "high-performance",
            // failIfMajorPerformanceCaveat: false,
            //premultipliedAlpha: false,
            // preserveDrawingBuffer: true,
            // stencil: true,
            // xrCompatible: false,
        };
        this.gl = this.canvas.getContext("webgl2", settings);
        if (!this.gl) {
            this.gl = this.canvas.getContext("webgl", settings);
            if (!this.gl)
                throw new Error("Failed to create WebGL2 | WebGL context");
        }
        const gl = this.gl;
        const available_extensions = gl.getSupportedExtensions();
        if (available_extensions.includes("OES_texture_float_linear")) {
            // Enable this extension otherwise I can't render a texture initialized with a float buffer
            gl.getExtension("OES_texture_float_linear");
        }
        if (this.debug) {
            console.log("available_extensions", available_extensions);
            //gl.getExtension("EXT_color_buffer_float")
            gl.getExtension("WEBGL_debug_shaders");
        }
        // flip texture
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1);
        //gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1)
        //gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1)
        // depth
        gl.enable(gl.DEPTH_TEST); // se lo abilito non funziona il blending, ma se non lo abilito non funziona il culling
        //gl.disable(gl.DEPTH_TEST)
        // it's not necessary to clear the color buffer
        gl.enable(gl.CULL_FACE);
        gl.cullFace(gl.BACK);
        //gl.cullFace(gl.FRONT_AND_BACK)
        //gl.frontFace(gl.CCW)
        // blend
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        //gl.blendFunc(gl.SRC_ALPHA, gl.ONE) // bene per i particellari, non per le mesh
        //gl.depthMask(false)
        //gl.colorMask(true, true, true, true)
        gl.disable(gl.DITHER);
        this.textureInternalFormat = gl.RGBA8;
        this.textureFormat = gl.RGBA;
        this.textureType = gl.UNSIGNED_BYTE;
        //this.textureInternalFormat = gl.RGBA32F
        //this.textureFormat = gl.RGBA
        //this.textureType = gl.FLOAT
    }
    resize(width, height) {
        const canvas = this.canvas;
        const gl = this.gl;
        canvas.width = width;
        canvas.height = height;
        gl.viewport(0, 0, width, height);
    }
    createCubeTexture(sources, size = Math.min(this.canvas.width, this.canvas.height), internalFormat = this.textureInternalFormat, type = this.textureType, format = this.textureFormat) {
        const gl = this.gl;
        const texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_CUBE_MAP, texture);
        for (let i = 0; i < 6; i++)
            gl.texStorage2D(gl.TEXTURE_2D, 1, internalFormat, size, size);
        gl.generateMipmap(gl.TEXTURE_CUBE_MAP);
        gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
        /////////////////
        function applyImages(images) {
            gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 0);
            images.forEach((image, i) => {
                gl.bindTexture(gl.TEXTURE_CUBE_MAP, texture);
                gl.texImage2D(gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, 0, internalFormat, format, type, image);
                //gl.texSubImage2D(gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, 0, 0, 0, size, size, format, type, image)
                gl.generateMipmap(gl.TEXTURE_CUBE_MAP);
            });
            gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1);
        }
        if (typeof sources === "string") {
            // load all cubemap faces
            const cubeMapImage = new Image();
            cubeMapImage.onload = () => {
                const faceSize = cubeMapImage.width / 4;
                const faces = [];
                faces.push(imageToCanvas(cubeMapImage, size, size, faceSize * 2, faceSize, faceSize, faceSize));
                faces.push(imageToCanvas(cubeMapImage, size, size, 0, faceSize, faceSize, faceSize));
                faces.push(imageToCanvas(cubeMapImage, size, size, faceSize, 0, faceSize, faceSize));
                faces.push(imageToCanvas(cubeMapImage, size, size, faceSize, faceSize * 2, faceSize, faceSize));
                faces.push(imageToCanvas(cubeMapImage, size, size, faceSize, faceSize, faceSize, faceSize));
                faces.push(imageToCanvas(cubeMapImage, size, size, faceSize * 3, faceSize, faceSize, faceSize));
                applyImages(faces);
            };
            cubeMapImage.onerror = () => console.error("Failed to load image", sources);
            cubeMapImage.src = sources;
        }
        else {
            const promises = sources.map((src, i) => {
                return new Promise((resolve, reject) => {
                    const image = new Image();
                    image.onload = () => resolve(imageToCanvas(image, size, size));
                    image.onerror = () => console.error("Failed to load image", src);
                    image.src = src;
                });
            });
            Promise.all(promises).then(applyImages);
        }
        /////////////////
        return texture;
    }
    createFramebufferAndTexture(source = null, width = this.canvas.width, height = this.canvas.height, internalFormat = this.textureInternalFormat, type = this.textureType, format = this.textureFormat, renderbuffer = false, params = { clamp: false, filter: "linear" }) {
        const gl = this.gl;
        const texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);
        if (source instanceof Buffer)
            gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, width, height, 0, format, type, source._data);
        else
            gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, width, height, 0, format, type, source);
        //gl.texStorage2D(gl.TEXTURE_2D, 1, internalFormat, width, height)
        //if (source) gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, width, height, format, type, source)
        //gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
        //gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, params.clamp ? gl.CLAMP_TO_EDGE : gl.REPEAT);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, params.clamp ? gl.CLAMP_TO_EDGE : gl.REPEAT);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, params.filter === "linear" ? gl.LINEAR : gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, params.filter === "linear" ? gl.LINEAR : gl.NEAREST);
        //gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
        //gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
        //gl.generateMipmap(gl.TEXTURE_2D)
        const framebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
        const fbs = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
        if (fbs !== gl.FRAMEBUFFER_COMPLETE) {
            console.log(`WARN: There is a problem with the framebuffer: ${FRAMEBUFFER_MESSAGES[fbs]}`);
        }
        if (renderbuffer) {
            const renderBuffer = gl.createRenderbuffer();
            gl.bindRenderbuffer(gl.RENDERBUFFER, renderBuffer);
            //gl.renderbufferStorageMultisample(gl.RENDERBUFFER, gl.getParameter(gl.MAX_SAMPLES), gl.RGBA8, width, height)
            gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, width, height);
            gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, renderBuffer);
            gl.bindRenderbuffer(gl.RENDERBUFFER, null);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.bindTexture(gl.TEXTURE_2D, null);
        return [framebuffer, texture];
    }
    bindUniform(gl, name, type, location, value, textureUnit = 0) {
        switch (true) {
            case type === "bool":
                return gl.uniform1i(location, value);
            case type === "float":
                return gl.uniform1f(location, value);
            case /float\[\d+\]/.test(type):
                return gl.uniform1fv(location, value);
            case type === "int":
                return gl.uniform1i(location, value);
            case type === "vec2":
                return gl.uniform2fv(location, value);
            case type === "vec3":
                return gl.uniform3fv(location, value);
            case type === "vec4":
                return gl.uniform4fv(location, value);
            case type === "mat4":
                return gl.uniformMatrix4fv(location, false, value);
            case type === "sampler2D":
                this.gl.activeTexture(this.gl.TEXTURE0 + textureUnit);
                this.gl.bindTexture(this.gl.TEXTURE_2D, value);
                return gl.uniform1i(location, textureUnit);
            case type === "samplerCube":
                this.gl.activeTexture(this.gl.TEXTURE0 + textureUnit);
                this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, value);
                return gl.uniform1i(location, textureUnit);
            default:
                console.warn("Unknown uniform type", type);
        }
    }
    static createShader(gl, type, source) {
        const shader = gl.createShader(type);
        if (!shader) {
            console.error("Failed to create shader", { type, source });
            return;
        }
        gl.shaderSource(shader, source);
        gl.compileShader(shader);
        const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
        if (success) {
            return [shader, null];
        }
        const error = gl.getShaderInfoLog(shader);
        gl.deleteShader(shader);
        return [null, error];
    }
    static createProgram(gl, vertexShader, fragmentShader, transformFeedbackVaryings = null, transformFeedbackMode = GL_SEPARATE_ATTRIBS) {
        var program = gl.createProgram();
        if (!program) {
            console.error("Failed to create program");
            return;
        }
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        if (transformFeedbackVaryings)
            gl.transformFeedbackVaryings(program, transformFeedbackVaryings, transformFeedbackMode);
        gl.linkProgram(program);
        const success = gl.getProgramParameter(program, gl.LINK_STATUS);
        if (success) {
            return program;
        }
        console.log(gl.getProgramInfoLog(program));
        gl.deleteProgram(program);
    }
}
