export default class Module {
    constructor(synth, name) {
        this.inputs = [];
        this.values = {};
        this.inputChangeCallbacks = {};
        this.lastInputs = {};
        this.bLoadedScripts = false;
        this.started = false;
        this.synth = synth;
        this.name = name;
    }
    async loadScripts(scripts, loadImmediately = false) {
        this.scritps = Array.isArray(scripts) ? scripts : [scripts];
        if (loadImmediately) {
            if (!this.bLoadedScripts) {
                if (this.scritps) {
                    for (const script of this.scritps)
                        await this.synth.loadScript(script);
                }
                this.bLoadedScripts = true;
            }
        }
    }
    setInput(name, defaultValue = undefined, required = false) {
        this.inputs.push({ name, required, defaultValue });
    }
    onInputChange(watcher, callback) {
        let names = watcher;
        let cb = callback;
        if (typeof watcher === "function") {
            names = this.inputs.map(({ name }) => name);
            cb = watcher;
        }
        for (const name of names) {
            if (!this.inputChangeCallbacks[name])
                this.inputChangeCallbacks[name] = [];
            this.inputChangeCallbacks[name].push(cb);
        }
    }
    async bind(inputs = {}) {
        if (this.inputs) {
            for (const input of this.inputs) {
                let value = inputs[input.name];
                if (typeof value === "undefined")
                    if (input.required)
                        throw new Error(`Module "${this.name}" init: missing required input "${input.name}"`);
                    else
                        value = input.defaultValue;
                this.values[input.name] = value;
            }
        }
        if (this.createCallback) {
            await this.start();
        }
        else {
            console.log(`[MODULE] Module "${this.name}" init: missing create callback`);
        }
    }
    getInputs() {
        return this.inputs.reduce((acc, input) => {
            const value = this.values[input.name];
            acc[input.name] = typeof value === "function" ? value(this.synth) : value;
            return acc;
        }, {});
    }
    async create(createCallback) {
        this.createCallback = createCallback;
    }
    async start() {
        if (!this.bLoadedScripts) {
            if (this.scritps) {
                for (const script of this.scritps)
                    await this.synth.loadScript(script);
            }
            this.bLoadedScripts = true;
        }
        if (Object.keys(this.values).length < this.inputs.filter(({ required }) => required).length) {
            throw new Error(`Module "${this.name}" init: missing required inputs ` + Object.keys(this.values).join(", "));
        }
        if (this.createCallback) {
            await this.createCallback(this.getInputs());
            this.started = true;
        }
    }
    resize(width, height) {
        this.start();
    }
    async stop() {
        this.started = false;
    }
    onUpdate(updateCallback) {
        this.updateCallback = updateCallback;
    }
    update() {
        if (!this.started)
            return;
        // detect input changes
        const inputs = this.getInputs();
        for (const name in inputs) {
            if (inputs[name] !== this.lastInputs[name]) {
                this.inputChangeCallbacks[name]?.forEach(callback => callback(inputs));
            }
        }
        this.lastInputs = { ...inputs };
        this.updateCallback(this.lastInputs);
    }
    help() {
        const inputs = this.inputs
            .sort((a, b) => (a.required && !b.required ? -1 : !a.required && b.required ? 1 : 0))
            .map(({ name, defaultValue, required }) => {
            return `${name}${required ? "*" : `=${defaultValue}`}`;
        });
        const helpMsg = `${this.name}.bind(\n\t${inputs.join(",\n\t")}\n)`;
        console.log(helpMsg);
    }
}
