{
    "name": "gydra-synth",
    "version": "1.2.0",
    "description": "",
    "scripts": {
        "start": "node generate-types.js && tsc --watch",
        "dev": "webpack serve --config webpack.config.js --mode development",
        "build": "webpack --config webpack.config.js --mode production && tsc && node generate-types.js",
        "format": "npx prettier ./src --write \"!**/*.json\" > /dev/null",
        "test": "node --experimental-vm-modules node_modules/jest/bin/jest.js",
        "coverage": "node --experimental-vm-modules node_modules/jest/bin/jest.js --coverage"
    },
    "publishConfig": {
        "@genbs:registry": "https://npm.pkg.github.com"
    },
    "repository": "https://github.com/genbs/gydra-synth",
    "directories": {
        "test": "tests"
    },
    "author": {
        "name": "Gennaro Bosone",
        "email": "gennaro.bs@gmail.com",
        "url": "https://github.com/genbs"
    },
    "main": "./dist/index.js",
    "browser": "./build/gydra.js",
    "types": "./dist/index.d.ts",
    "license": "ISC",
    "devDependencies": {
        "@types/jest": "^29.5.11",
        "@types/meyda": "^4.3.3",
        "canvas": "^2.11.2",
        "jest": "^29.7.0",
        "jest-webgl-canvas-mock": "github:genbs/jest-webgl-canvas-mock#add-missing-webgl2-methods",
        "ts-jest": "^29.1.1",
        "ts-loader": "^9.5.1",
        "typescript": "^5.1.6",
        "webpack": "^5.88.2",
        "webpack-cli": "^5.1.4",
        "webpack-dev-server": "^4.15.1"
    },
    "dependencies": {
        "earcut": "^2.2.4",
        "gl-matrix": "^3.4.3",
        "meyda": "^5.6.2",
        "primitive-geometry": "^2.10.0",
        "webgl-gltf": "git+https://github.com/genbs/webgl-gltf.git#master"
    }
}
