import { GLSL_ARG } from "../typeMapping";
export default [
    {
        name: "color",
        type: "color",
        inputs: {
            r: 1,
            g: 1,
            b: 1,
            a: 1,
        },
        glsl: `
            vec4 _c0 = ${GLSL_ARG.color0};
            
            vec4 c = vec4(r, g, b, a);
            vec4 pos = step(0.0, c); // detect whether negative
            // if > 0, return r * _c0
            // if < 0 return (1.0-r) * _c0
        
            return vec4(mix((1.0-_c0)*abs(c), c*_c0, pos));
        `,
    },
    {
        name: "posterize",
        type: "color",
        inputs: {
            bins: 3,
            gamma: 0.6,
        },
        glsl: `
            vec4 c2 = pow(${GLSL_ARG.color0}, vec4(gamma));
            c2 *= vec4(bins);
            c2 = floor(c2);
            c2/= vec4(bins);
            c2 = pow(c2, vec4(1.0/gamma));
            
            return vec4(c2.xyz, ${GLSL_ARG.color0}.a);
        `,
    },
    {
        name: "shift",
        type: "color",
        inputs: {
            r: 0.5,
            g: 0,
            b: 0,
            a: 0,
        },
        glsl: `
            vec4 c2 = vec4(${GLSL_ARG.color0});
            
            c2.r = fract(c2.r + r);
            c2.g = fract(c2.g + g);
            c2.b = fract(c2.b + b);
            c2.a = fract(c2.a + a);

            return vec4(c2.rgba);
        `,
    },
    {
        name: "invert",
        type: "color",
        inputs: { amount: 1 },
        glsl: `
            vec4 _c0 = ${GLSL_ARG.color0};
            return vec4( (1.0 - _c0.rgb) * amount + _c0.rgb * ( 1.0 - amount), _c0.a);
        `,
    },
    {
        name: "contrast",
        type: "color",
        inputs: {
            amount: 1.6,
        },
        glsl: `
            vec4 _c0 = ${GLSL_ARG.color0};
			vec4 mid = vec4(0.5);
            vec4 c = (_c0 - mid) * amount + mid;
            return vec4(c.rgb, _c0.a);
        `,
    },
    {
        name: "brightness",
        type: "color",
        inputs: {
            amount: 0.4,
        },
        glsl: `
            vec4 _c0 = ${GLSL_ARG.color0};
            return vec4(_c0.rgb + vec3(amount), _c0.a);
        `,
    },
    {
        name: "luma",
        type: "color",
        inputs: {
            threshold: 0.5,
            tolerance: 0.1,
        },
        glsl: `
            vec4 _c0 = ${GLSL_ARG.color0};
            float a = smoothstep(threshold-(tolerance+0.0000001), threshold+(tolerance+0.0000001), luminance(_c0.rgb));
            
            return vec4(_c0.rgb*a, a);
        `,
        require: ["luminance"],
    },
    {
        name: "thresh",
        type: "color",
        inputs: {
            threshold: 0.5,
            tolerance: 0.04,
        },
        glsl: `
            vec4 _c0 = ${GLSL_ARG.color0};
            
            return vec4(vec3(smoothstep(threshold-(tolerance+0.0000001), threshold+(tolerance+0.0000001), luminance(_c0.rgb))), _c0.a);
        `,
        require: ["luminance"],
    },
    {
        name: "saturate",
        type: "color",
        inputs: { amount: 2 },
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			
         	float lum = luminance(_c0.rgb);
			
         	return vec4(mix(vec3(lum), _c0.rgb, amount), _c0.a);
		`,
        require: ["luminance"],
    },
    {
        name: "hue",
        type: "color",
        inputs: {
            hue: 0.4,
        },
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			vec3 c = rgbToHsv(_c0.rgb);
			c.r += hue;
			//  c.r = fract(c.r);

			return vec4(hsvToRgb(c), _c0.a);
		`,
        require: ["rgbToHsv", "hsvToRgb"],
    },
    {
        name: "colorama",
        type: "color",
        inputs: {
            amount: 0.005,
        },
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};

			if (amount == 0.) {
				return c0;
			}

			vec3 c = rgbToHsv(_c0.rgb);
			c += vec3(amount);
			c = hsvToRgb(c);
			c = fract(c);
			
			return vec4(c, _c0.a);
		`,
        require: ["rgbToHsv", "hsvToRgb"],
        lang: "!",
    },
    {
        name: "sum",
        type: "color",
        inputs: { scale: 1 },
        glsl: `   
			vec4 _c0 = ${GLSL_ARG.color0};
            vec4 v = _c0 * s;
			
            return _c0 + v.r + v.g + v.b + v.a;
		`,
    },
    {
        name: "r",
        type: "color",
        inputs: {
            scale: 1,
            offset: 0,
        },
        glsl: `return vec4(${GLSL_ARG.color0}.r * scale + offset);`,
    },
    {
        name: "g",
        type: "color",
        inputs: {
            scale: 1,
            offset: 0,
        },
        glsl: `return vec4(${GLSL_ARG.color0}.g * scale + offset);`,
    },
    {
        name: "b",
        type: "color",
        inputs: {
            scale: 1,
            offset: 0,
        },
        glsl: `return vec4(${GLSL_ARG.color0}.b * scale + offset);`,
    },
    {
        name: "a",
        type: "color",
        inputs: {
            scale: 1,
            offset: 0,
        },
        glsl: `return vec4(${GLSL_ARG.color0}.a * scale + offset);`,
    },
];
