import { GLSL_ARG } from "../../typeMapping";
export default [
    {
        name: "translate",
        type: "coord",
        inputs: {
            translateX: 0.5,
            translateY: 0.5,
            translateZ: 0.5,
            speedX: 0,
            speedY: 0,
            speedZ: 0,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st};
		 	_st.x += translateX + time * speedX;
		 	_st.y += translateY + time * speedY;
		 	_st.z += translateZ + time * speedZ;
			return _st;
		`,
    },
    {
        name: "translateY",
        type: "coord",
        inputs: {
            translateY: 0.5,
            speed: 0,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st};
			_st.y += translateY + time * speed;
			return _st;
		`,
    },
    {
        name: "translateX",
        type: "coord",
        inputs: {
            translateX: 0.5,
            speed: 0,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st};
			_st.x += translateX + time * speed;
			return _st;
		`,
    },
    {
        name: "translateZ",
        type: "coord",
        inputs: {
            translateZ: 0.5,
            speed: 0,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st};
			_st.z += translateZ + time * speed;
			return _st;
		`,
    },
    {
        name: "rotateX",
        type: "coord",
        inputs: {
            angle: 10,
            speed: 0,
        },
        require: ["rotate3d"],
        defines: ["RIGHT"],
        glsl: `
			return rotate3d(${GLSL_ARG.st}, RIGHT, (time * speed + angle) * -1.);
		`,
    },
    {
        name: "rotateY",
        type: "coord",
        inputs: {
            angle: 10,
            speed: 0,
        },
        require: ["rotate3d"],
        defines: ["UP"],
        glsl: `
			return rotate3d(${GLSL_ARG.st}, UP, (time * speed + angle) * -1.);
		`,
    },
    {
        name: "rotateZ",
        type: "coord",
        inputs: {
            angle: 10,
            speed: 0,
        },
        require: ["rotate3d"],
        defines: ["FORWARD"],
        glsl: `
			return rotate3d(${GLSL_ARG.st}, FORWARD, (time * speed + angle) * -1.);
		`,
    },
    {
        name: "ripple",
        type: "coord",
        inputs: {
            amount: 1,
            speed: 1,
            phase: 0,
            f: 0.05,
            k: 1,
        },
        glsl: `
			float dist = pow(length(${GLSL_ARG.st} - .5), k);
			float d = sin(dist * amount - time * 2. * speed + phase);
			vec3 dir = normalize(${GLSL_ARG.st} - .5);

			return ${GLSL_ARG.st} + d * dir * f;
		`,
    },
    {
        name: "glitch",
        type: "coord",
        inputs: {
            amount: 1,
            offset: 0.1,
            gap: 0.05,
            gapOffset: 0.5,
        },
        require: ["luminance"],
        glsl: `
			vec3 sum = vec3(.0);
			for (int t = 0; t < 5; t++) {
				vec3 off = ${GLSL_ARG.st} + vec3((float(t) - 2.5) * offset);
				sum += luminance(texture(prevFrame, off).rgb);
			}

			sum /= float(5.);
			sum = max(sum, gapOffset - gap);
			sum = min(sum, gapOffset + gap);
			
			return _st + sum * amount;
		`,
    },
    {
        name: "polar",
        type: "coord",
        inputs: {
            reps: 1,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy - 0.5;
	
			_st = toPolar(_st);
			_st.x *= reps;
			_st.x = fract(_st.x);

			return vec3(_st, ${GLSL_ARG.st}.z);
		`,
        require: ["polar"],
        defines: ["TWO_PI"],
    },
    {
        name: "mirrorX",
        type: "coord",
        inputs: {
            pos: 0,
            coverage: 1,
            invert: 0,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st};
			float k = abs( fract(_st.x / coverage) - (1.0 - 0.5 - pos) ) + 0.5 - pos;
			_st.x = ( 0.0 + mix(-k, k, invert) ) * coverage;
			return _st;
		`,
    },
    {
        name: "mirrorY",
        type: "coord",
        inputs: {
            pos: 0,
            coverage: 1,
            invert: 0,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st};
			float k = abs( fract(_st.y / coverage) - (1.0 - 0.5 - pos) ) + 0.5 - pos;
			_st.y = ( 0.0 + mix(-k, k, invert) ) * coverage; 
			return _st;
		`,
    },
    {
        name: "inversion",
        type: "coord",
        inputs: {
            amount: 1,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st};
			_st /= dot(_st, _st) * amount; 
			return _st;
		`,
    },
];
