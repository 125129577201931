import { GLSL_ARG } from "../../typeMapping";
export default [
    {
        name: "add2",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = min(${GLSL_ARG.color0}.r+${GLSL_ARG.color1}.r,1.0);rgb.g = min(${GLSL_ARG.color0}.g+${GLSL_ARG.color1}.g,1.0);rgb.b = min(${GLSL_ARG.color0}.b+${GLSL_ARG.color1}.b,1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "average",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color0}.r+${GLSL_ARG.color1}.r)/2.0;rgb.g = (${GLSL_ARG.color0}.g+${GLSL_ARG.color1}.g)/2.0;rgb.b = (${GLSL_ARG.color0}.b+${GLSL_ARG.color1}.b)/2.0;
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "colorBurn",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color1}.r==0.0)?${GLSL_ARG.color1}.r:max((1.0-((1.0-${GLSL_ARG.color0}.r)/${GLSL_ARG.color1}.r)),0.0);rgb.g = (${GLSL_ARG.color1}.g==0.0)?${GLSL_ARG.color1}.g:max((1.0-((1.0-${GLSL_ARG.color0}.g)/${GLSL_ARG.color1}.g)),0.0);rgb.b = (${GLSL_ARG.color1}.b==0.0)?${GLSL_ARG.color1}.b:max((1.0-((1.0-${GLSL_ARG.color0}.b)/${GLSL_ARG.color1}.b)),0.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "colorDodge",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color1}.r==1.0)?${GLSL_ARG.color1}.r:min(${GLSL_ARG.color0}.r/(1.0-${GLSL_ARG.color1}.r),1.0);rgb.g = (${GLSL_ARG.color1}.g==1.0)?${GLSL_ARG.color1}.g:min(${GLSL_ARG.color0}.g/(1.0-${GLSL_ARG.color1}.g),1.0);rgb.b = (${GLSL_ARG.color1}.b==1.0)?${GLSL_ARG.color1}.b:min(${GLSL_ARG.color0}.b/(1.0-${GLSL_ARG.color1}.b),1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "darken",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = min(${GLSL_ARG.color1}.r,${GLSL_ARG.color0}.r);rgb.g = min(${GLSL_ARG.color1}.g,${GLSL_ARG.color0}.g);rgb.b = min(${GLSL_ARG.color1}.b,${GLSL_ARG.color0}.b);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "difference",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = abs(${GLSL_ARG.color0}.r-${GLSL_ARG.color1}.r);rgb.g = abs(${GLSL_ARG.color0}.g-${GLSL_ARG.color1}.g);rgb.b = abs(${GLSL_ARG.color0}.b-${GLSL_ARG.color1}.b);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "divide",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = min(${GLSL_ARG.color0}.r/(${GLSL_ARG.color1}.r+0.00000001),1.0);rgb.g = min(${GLSL_ARG.color0}.g/(${GLSL_ARG.color1}.g+0.00000001),1.0);rgb.b = min(${GLSL_ARG.color0}.b/(${GLSL_ARG.color1}.b+0.00000001),1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "exclusion",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = ${GLSL_ARG.color0}.r+${GLSL_ARG.color1}.r-2.0*${GLSL_ARG.color0}.r*${GLSL_ARG.color1}.r;rgb.g = ${GLSL_ARG.color0}.g+${GLSL_ARG.color1}.g-2.0*${GLSL_ARG.color0}.g*${GLSL_ARG.color1}.g;rgb.b = ${GLSL_ARG.color0}.b+${GLSL_ARG.color1}.b-2.0*${GLSL_ARG.color0}.b*${GLSL_ARG.color1}.b;
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "glow",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color0}.r==1.0)?${GLSL_ARG.color0}.r:min(${GLSL_ARG.color1}.r*${GLSL_ARG.color1}.r/(1.0-${GLSL_ARG.color0}.r),1.0);rgb.g = (${GLSL_ARG.color0}.g==1.0)?${GLSL_ARG.color0}.g:min(${GLSL_ARG.color1}.g*${GLSL_ARG.color1}.g/(1.0-${GLSL_ARG.color0}.g),1.0);rgb.b = (${GLSL_ARG.color0}.b==1.0)?${GLSL_ARG.color0}.b:min(${GLSL_ARG.color1}.b*${GLSL_ARG.color1}.b/(1.0-${GLSL_ARG.color0}.b),1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "hardLight",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = ${GLSL_ARG.color1}.r<0.5?(2.0*${GLSL_ARG.color1}.r*${GLSL_ARG.color0}.r):(1.0-2.0*(1.0-${GLSL_ARG.color1}.r)*(1.0-${GLSL_ARG.color0}.r));rgb.g = ${GLSL_ARG.color1}.g<0.5?(2.0*${GLSL_ARG.color1}.g*${GLSL_ARG.color0}.g):(1.0-2.0*(1.0-${GLSL_ARG.color1}.g)*(1.0-${GLSL_ARG.color0}.g));rgb.b = ${GLSL_ARG.color1}.b<0.5?(2.0*${GLSL_ARG.color1}.b*${GLSL_ARG.color0}.b):(1.0-2.0*(1.0-${GLSL_ARG.color1}.b)*(1.0-${GLSL_ARG.color0}.b));
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "hardMix",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (((${GLSL_ARG.color1}.r<0.5)?((${GLSL_ARG.color1}.r==0.0)?(${GLSL_ARG.color1}.r):max((1.0-((1.0-${GLSL_ARG.color0}.r)/(2.0*${GLSL_ARG.color1}.r))),0.0)):(((2.0*(${GLSL_ARG.color1}.r-0.5))==1.0)?(2.0*(${GLSL_ARG.color1}.r-0.5)):min(${GLSL_ARG.color0}.r/(1.0-(2.0*(${GLSL_ARG.color1}.r-0.5))),1.0)))<0.5)?0.0:1.0;rgb.g = (((${GLSL_ARG.color1}.g<0.5)?((${GLSL_ARG.color1}.g==0.0)?(${GLSL_ARG.color1}.g):max((1.0-((1.0-${GLSL_ARG.color0}.g)/(2.0*${GLSL_ARG.color1}.g))),0.0)):(((2.0*(${GLSL_ARG.color1}.g-0.5))==1.0)?(2.0*(${GLSL_ARG.color1}.g-0.5)):min(${GLSL_ARG.color0}.g/(1.0-(2.0*(${GLSL_ARG.color1}.g-0.5))),1.0)))<0.5)?0.0:1.0;rgb.b = (((${GLSL_ARG.color1}.b<0.5)?((${GLSL_ARG.color1}.b==0.0)?(${GLSL_ARG.color1}.b):max((1.0-((1.0-${GLSL_ARG.color0}.b)/(2.0*${GLSL_ARG.color1}.b))),0.0)):(((2.0*(${GLSL_ARG.color1}.b-0.5))==1.0)?(2.0*(${GLSL_ARG.color1}.b-0.5)):min(${GLSL_ARG.color0}.b/(1.0-(2.0*(${GLSL_ARG.color1}.b-0.5))),1.0)))<0.5)?0.0:1.0;
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "lighten",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = max(${GLSL_ARG.color1}.r,${GLSL_ARG.color0}.r);rgb.g = max(${GLSL_ARG.color1}.g,${GLSL_ARG.color0}.g);rgb.b = max(${GLSL_ARG.color1}.b,${GLSL_ARG.color0}.b);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "linearBurn",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = max(${GLSL_ARG.color0}.r+${GLSL_ARG.color1}.r-1.0,0.0);rgb.g = max(${GLSL_ARG.color0}.g+${GLSL_ARG.color1}.g-1.0,0.0);rgb.b = max(${GLSL_ARG.color0}.b+${GLSL_ARG.color1}.b-1.0,0.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "linearDodge",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = min(${GLSL_ARG.color0}.r+${GLSL_ARG.color1}.r,1.0);rgb.g = min(${GLSL_ARG.color0}.g+${GLSL_ARG.color1}.g,1.0);rgb.b = min(${GLSL_ARG.color0}.b+${GLSL_ARG.color1}.b,1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "linearLight",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = ${GLSL_ARG.color1}.r<0.5?(max(${GLSL_ARG.color0}.r+(2.0*${GLSL_ARG.color1}.r)-1.0,0.0)):min(${GLSL_ARG.color0}.r+(2.0*(${GLSL_ARG.color1}.r-0.5)),1.0);rgb.g = ${GLSL_ARG.color1}.g<0.5?(max(${GLSL_ARG.color0}.g+(2.0*${GLSL_ARG.color1}.g)-1.0,0.0)):min(${GLSL_ARG.color0}.g+(2.0*(${GLSL_ARG.color1}.g-0.5)),1.0);rgb.b = ${GLSL_ARG.color1}.b<0.5?(max(${GLSL_ARG.color0}.b+(2.0*${GLSL_ARG.color1}.b)-1.0,0.0)):min(${GLSL_ARG.color0}.b+(2.0*(${GLSL_ARG.color1}.b-0.5)),1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "multiply",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = ${GLSL_ARG.color0}.r*${GLSL_ARG.color1}.r;rgb.g = ${GLSL_ARG.color0}.g*${GLSL_ARG.color1}.g;rgb.b = ${GLSL_ARG.color0}.b*${GLSL_ARG.color1}.b;
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "negation",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = 1.0-abs(1.0-${GLSL_ARG.color0}.r-${GLSL_ARG.color1}.r);rgb.g = 1.0-abs(1.0-${GLSL_ARG.color0}.g-${GLSL_ARG.color1}.g);rgb.b = 1.0-abs(1.0-${GLSL_ARG.color0}.b-${GLSL_ARG.color1}.b);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "overlay",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = ${GLSL_ARG.color0}.r<0.5?(2.0*${GLSL_ARG.color0}.r*${GLSL_ARG.color1}.r):(1.0-2.0*(1.0-${GLSL_ARG.color0}.r)*(1.0-${GLSL_ARG.color1}.r));rgb.g = ${GLSL_ARG.color0}.g<0.5?(2.0*${GLSL_ARG.color0}.g*${GLSL_ARG.color1}.g):(1.0-2.0*(1.0-${GLSL_ARG.color0}.g)*(1.0-${GLSL_ARG.color1}.g));rgb.b = ${GLSL_ARG.color0}.b<0.5?(2.0*${GLSL_ARG.color0}.b*${GLSL_ARG.color1}.b):(1.0-2.0*(1.0-${GLSL_ARG.color0}.b)*(1.0-${GLSL_ARG.color1}.b));
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "phoenix",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = min(${GLSL_ARG.color0}.r,${GLSL_ARG.color1}.r)-max(${GLSL_ARG.color0}.r,${GLSL_ARG.color1}.r)+vec3(1.0);rgb.g = min(${GLSL_ARG.color0}.g,${GLSL_ARG.color1}.g)-max(${GLSL_ARG.color0}.g,${GLSL_ARG.color1}.g)+vec3(1.0);rgb.b = min(${GLSL_ARG.color0}.b,${GLSL_ARG.color1}.b)-max(${GLSL_ARG.color0}.b,${GLSL_ARG.color1}.b)+vec3(1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "pinLight",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color1}.r<0.5)?min(${GLSL_ARG.color0}.r,2.0*${GLSL_ARG.color1}.r):max(${GLSL_ARG.color0}.r,2.0*(${GLSL_ARG.color1}.r-0.5));rgb.g = (${GLSL_ARG.color1}.g<0.5)?min(${GLSL_ARG.color0}.g,2.0*${GLSL_ARG.color1}.g):max(${GLSL_ARG.color0}.g,2.0*(${GLSL_ARG.color1}.g-0.5));rgb.b = (${GLSL_ARG.color1}.b<0.5)?min(${GLSL_ARG.color0}.b,2.0*${GLSL_ARG.color1}.b):max(${GLSL_ARG.color0}.b,2.0*(${GLSL_ARG.color1}.b-0.5));
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "reflect",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color1}.r==1.0)?${GLSL_ARG.color1}.r:min(${GLSL_ARG.color0}.r*${GLSL_ARG.color0}.r/(1.0-${GLSL_ARG.color1}.r),1.0);rgb.g = (${GLSL_ARG.color1}.g==1.0)?${GLSL_ARG.color1}.g:min(${GLSL_ARG.color0}.g*${GLSL_ARG.color0}.g/(1.0-${GLSL_ARG.color1}.g),1.0);rgb.b = (${GLSL_ARG.color1}.b==1.0)?${GLSL_ARG.color1}.b:min(${GLSL_ARG.color0}.b*${GLSL_ARG.color0}.b/(1.0-${GLSL_ARG.color1}.b),1.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "screen",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = 1.0-((1.0-${GLSL_ARG.color0}.r)*(1.0-${GLSL_ARG.color1}.r));rgb.g = 1.0-((1.0-${GLSL_ARG.color0}.g)*(1.0-${GLSL_ARG.color1}.g));rgb.b = 1.0-((1.0-${GLSL_ARG.color0}.b)*(1.0-${GLSL_ARG.color1}.b));
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "softLight",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color1}.r<0.5)?(2.0*${GLSL_ARG.color0}.r*${GLSL_ARG.color1}.r+${GLSL_ARG.color0}.r*${GLSL_ARG.color0}.r*(1.0-2.0*${GLSL_ARG.color1}.r)):(sqrt(${GLSL_ARG.color0}.r)*(2.0*${GLSL_ARG.color1}.r-1.0)+2.0*${GLSL_ARG.color0}.r*(1.0-${GLSL_ARG.color1}.r));rgb.g = (${GLSL_ARG.color1}.g<0.5)?(2.0*${GLSL_ARG.color0}.g*${GLSL_ARG.color1}.g+${GLSL_ARG.color0}.g*${GLSL_ARG.color0}.g*(1.0-2.0*${GLSL_ARG.color1}.g)):(sqrt(${GLSL_ARG.color0}.g)*(2.0*${GLSL_ARG.color1}.g-1.0)+2.0*${GLSL_ARG.color0}.g*(1.0-${GLSL_ARG.color1}.g));rgb.b = (${GLSL_ARG.color1}.b<0.5)?(2.0*${GLSL_ARG.color0}.b*${GLSL_ARG.color1}.b+${GLSL_ARG.color0}.b*${GLSL_ARG.color0}.b*(1.0-2.0*${GLSL_ARG.color1}.b)):(sqrt(${GLSL_ARG.color0}.b)*(2.0*${GLSL_ARG.color1}.b-1.0)+2.0*${GLSL_ARG.color0}.b*(1.0-${GLSL_ARG.color1}.b));
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "subtract",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = max(${GLSL_ARG.color0}.r+${GLSL_ARG.color1}.r-1.0,0.0);rgb.g = max(${GLSL_ARG.color0}.g+${GLSL_ARG.color1}.g-1.0,0.0);rgb.b = max(${GLSL_ARG.color0}.b+${GLSL_ARG.color1}.b-1.0,0.0);
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
    {
        name: "vividLight",
        type: "combine",
        inputs: [
            {
                name: "amount",
                type: "float",
                default: 1,
            },
        ],
        glsl: `vec3 rgb;
            rgb.r = (${GLSL_ARG.color1}.r<0.5)?((${GLSL_ARG.color1}.r==0.0)?(${GLSL_ARG.color1}.r):max((1.0-((1.0-${GLSL_ARG.color0}.r)/(2.0*${GLSL_ARG.color1}.r))),0.0)):(((2.0*(${GLSL_ARG.color1}.r-0.5))==1.0)?(2.0*(${GLSL_ARG.color1}.r-0.5)):min(${GLSL_ARG.color0}.r/(1.0-(2.0*(${GLSL_ARG.color1}.r-0.5))),1.0));rgb.g = (${GLSL_ARG.color1}.g<0.5)?((${GLSL_ARG.color1}.g==0.0)?(${GLSL_ARG.color1}.g):max((1.0-((1.0-${GLSL_ARG.color0}.g)/(2.0*${GLSL_ARG.color1}.g))),0.0)):(((2.0*(${GLSL_ARG.color1}.g-0.5))==1.0)?(2.0*(${GLSL_ARG.color1}.g-0.5)):min(${GLSL_ARG.color0}.g/(1.0-(2.0*(${GLSL_ARG.color1}.g-0.5))),1.0));rgb.b = (${GLSL_ARG.color1}.b<0.5)?((${GLSL_ARG.color1}.b==0.0)?(${GLSL_ARG.color1}.b):max((1.0-((1.0-${GLSL_ARG.color0}.b)/(2.0*${GLSL_ARG.color1}.b))),0.0)):(((2.0*(${GLSL_ARG.color1}.b-0.5))==1.0)?(2.0*(${GLSL_ARG.color1}.b-0.5)):min(${GLSL_ARG.color0}.b/(1.0-(2.0*(${GLSL_ARG.color1}.b-0.5))),1.0));
            ${GLSL_ARG.color1}.a *= amount;
            vec4 blended = vec4(mix(${GLSL_ARG.color0}.rgb, rgb, ${GLSL_ARG.color1}.a), 1.0);
            vec4 over = ${GLSL_ARG.color1}+(${GLSL_ARG.color0}*(1.0-${GLSL_ARG.color1}.a));
            return mix(blended, over, 1.0-${GLSL_ARG.color0}.a);
      `,
    },
];
