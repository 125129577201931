import { GLSL_ARG } from "../../typeMapping";
export default [
    {
        name: "boost",
        type: "color",
        inputs: {
            refColor: [0, 0, 0],
            amount: 32,
        },
        glsl: `
			vec4 color = ${GLSL_ARG.color0};

			float weight = dot(normalize(color.rgb), normalize(refColor));
			weight = pow(weight, amount);

			return vec4(mix(vec3(luminance(color.rgb)), color.rgb, weight), color.a);
		`,
        require: ["luminance"],
    },
    {
        name: "isolate",
        type: "color",
        inputs: {
            toIsolate: [0, 0, 0],
            threshold: 0.1,
        },
        glsl: `
            vec4 color = ${GLSL_ARG.color0};

            if (abs(color.r - toIsolate.r) < threshold && abs(color.g - toIsolate.g) < threshold && abs(color.b - toIsolate.b) < threshold) {
                return vec4(1.);
            }

            return vec4(0.0);  
        `,
        transform: (toIsolate, threshold) => {
            if (typeof toIsolate === "string") {
                toIsolate = toIsolate
                    .replaceAll("#", "")
                    .match(/.{1,2}/g)
                    .map(v => parseInt(v, 16) / 255);
            }
            return [toIsolate, threshold];
        },
    },
    {
        name: "clear",
        type: "color",
        inputs: {
            toClear: [0, 0, 0],
            threshold: 0.1,
        },
        glsl: `
            vec4 color = ${GLSL_ARG.color0};

            if (abs(color.r - toClear.r) > threshold && abs(color.g - toClear.g) > threshold && abs(color.b - toClear.b) > threshold) {
                return color;
            }

            return vec4(color.rgb, .0);  
        `,
        transform: (toIsolate, threshold) => {
            if (typeof toIsolate === "string") {
                toIsolate = toIsolate
                    .replaceAll("#", "")
                    .match(/.{1,2}/g)
                    .map(v => parseInt(v, 16) / 255);
            }
            return [toIsolate, threshold];
        },
    },
    {
        name: "alpha",
        type: "color",
        inputs: {
            amount: 1,
        },
        glsl: `
			float alpha = ${GLSL_ARG.color0}.a * amount;
			return vec4(${GLSL_ARG.color0}.rgb, alpha);
		`,
    },
];
