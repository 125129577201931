import code from "./code";
import color from "./color";
import combine from "./combine";
import combineCoord from "./combineCoord";
import coord from "./coord";
import custom_functions from "./custom_functions";
import src from "./src";
const functions = [...src, ...color, ...coord, ...combineCoord, ...combine];
export const glsl_functions = [...functions, ...code, ...custom_functions];
export function setFunction(fn) {
    const index = glsl_functions.findIndex(({ name }) => name === fn.name);
    if (index !== -1) {
        console.warn(`Function ${fn.name} already exists`);
        return;
    }
    glsl_functions.push(fn);
}
