import { GLSL_ARG } from "../typeMapping";
export default [
    {
        name: "scroll",
        type: "coord",
        inputs: {
            scrollX: 0.5,
            scrollY: 0.5,
            speedX: 0,
            speedY: 0,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy;
		 	_st.x += scrollX + time*speedX;
		 	_st.y += scrollY + time*speedY;
			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
        lang: "--++",
    },
    {
        name: "scrollY",
        type: "coord",
        inputs: {
            scrollY: 0.5,
            speed: 0,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy;
			_st.y += scrollY + time * speed;
			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
        lang: "--",
    },
    {
        name: "scrollX",
        type: "coord",
        inputs: {
            scrollX: 0.5,
            speed: 0,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy;
			_st.x += scrollX + time * speed;
			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
        lang: "++",
    },
    {
        name: "repeat",
        type: "coord",
        inputs: {
            repeatX: 3,
            repeatY: 3,
            repeatZ: 3,
            offsetX: 0,
            offsetY: 0,
            offsetZ: 0,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st} * vec3(repeatX, repeatY, repeatZ);
			_st.x += step(1., mod(_st.y,2.0)) * offsetX;
			_st.y += step(1., mod(_st.x,2.0)) * offsetY;
			_st.z += step(1., mod(_st.z,2.0)) * offsetZ;
			return fract(_st);
		`,
        lang: "#",
    },
    {
        name: "repeatX",
        type: "coord",
        inputs: {
            reps: 3,
            offset: 0,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy * vec2(reps, 1.0);
			//  float f =  mod(_st.y,2.0);
			_st.x += step(1., mod(_st.y, 2.0)) * offset;
			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
        lang: "#x",
    },
    {
        name: "repeatY",
        type: "coord",
        inputs: {
            reps: 3,
            offset: 0,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy * vec2(1.0, reps);
			//  float f =  mod(_st.y,2.0);
			_st.y += step(1., mod(_st.x, 2.0)) * offset;
			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
        lang: "#y",
    },
    {
        name: "rotate",
        type: "coord",
        inputs: {
            angle: 10,
            speed: 0,
        },
        require: ["rotate3d"],
        defines: ["FORWARD"],
        glsl: `
			vec3 _st = ${GLSL_ARG.st} - 0.5;
			_st = rotate3d(_st, FORWARD, (time * speed + angle) * -1.);
			return _st + 0.5;
		`,
        lang: "@",
    },
    {
        name: "scale",
        type: "coord",
        inputs: {
            amount: 1.5,
            xMult: 1,
            yMult: 1,
            zMult: 1,
            offsetX: 0.5,
            offsetY: 0.5,
            offsetZ: 0.5,
        },
        glsl: `   
			vec3 offset = vec3(offsetX, offsetY, offsetZ);
			vec3 a = vec3(xMult, yMult, zMult) * amount;

			vec3 xyz = ${GLSL_ARG.st} - offset.xyz;

			xyz *= 1.0 / a;
			xyz += offset;
			
			return xyz;
		`,
        lang: "^",
    },
    {
        name: "pixelate",
        type: "coord",
        inputs: {
            pixelX: 20,
            pixelY: 20,
            pixelZ: 20,
        },
        glsl: `
			vec3 xyz = vec3(pixelX, pixelY, pixelZ);
		
			return floor(${GLSL_ARG.st} * xyz) / xyz;
		`,
        lang: "##",
    },
    {
        name: "kaleid",
        type: "coord",
        inputs: {
            nSides: 4,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy - 0.5;
			float r = length(_st);
			float a = atan(_st.y, _st.x);
			a = mod(a,TWO_PI / nSides);
			a = abs(a-TWO_PI / nSides / 2.);
			return vec3(r*vec2(cos(a), sin(a)), ${GLSL_ARG.st}.z);
		`,
        defines: ["TWO_PI"],
        lang: "@@",
    },
];
