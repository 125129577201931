import { glsl_functions, GLSLSource } from "./glsl";
import Buffer, { bufferType, GL_FLOAT, glTypeToTypedArray } from "./shader/buffer";
import { gridSize } from "./utils";
const srcFunction = glsl_functions.find(f => f.name === "src");
let t = 1;
export default class Texture {
    // attachedShader?: Shader
    constructor(synth, props = {}) {
        if (props instanceof Buffer) {
            const [width, height] = gridSize(props._size);
            props = {
                data: props,
                type: props._type,
                internalFormat: props._type === GL_FLOAT ? synth.renderer.gl.RGBA32F : synth.renderer.gl.RGBA8,
                format: synth.renderer.gl.RGBA,
                width,
                height,
            };
        }
        this.name = props.name || "texture_" + t++;
        this.synth = synth;
        this.width = props.width || synth.width;
        this.height = props.height || synth.height;
        this.ratio = this.width / this.height;
        this._internalFormat = props.internalFormat || synth.renderer.textureInternalFormat;
        this._format = props.format || synth.renderer.textureFormat;
        this._type = bufferType(props.type || synth.renderer.textureType);
        this.len = props.len || 1;
        this.index = 0;
        this.textures = new Array(this.len);
        this.framebuffers = new Array(this.len);
        // this.needsUpdate = false
        this.updates = 0;
        this.create(props.data);
        this.synth.textures.push(this);
    }
    float() {
        const gl = this.synth.renderer.gl;
        this._internalFormat = gl.RGBA32F;
        this._format = gl.RGBA;
        this._type = gl.FLOAT;
        return this;
    }
    ubyte() {
        const gl = this.synth.renderer.gl;
        this._internalFormat = gl.RGBA8;
        this._format = gl.RGBA;
        this._type = gl.UNSIGNED_BYTE;
        return this;
    }
    type(type) {
        this._type = bufferType(type);
        return this;
    }
    format(format, internalFormat) {
        this._format = format;
        this._internalFormat = internalFormat || format;
        return this;
    }
    internalFormat(internalFormat) {
        this._internalFormat = internalFormat;
        return this;
    }
    realloc(len) {
        this.len = len;
        this.textures = new Array(this.len);
        this.framebuffers = new Array(this.len);
        return this.create(this.source);
    }
    create(source = null, textureParams) {
        const gl = this.synth.renderer.gl;
        if (source && "width" in source && source.width && "height" in source && source.height) {
            this.width = source.width;
            this.height = source.height;
        }
        else if (source && "videoWidth" in source && source.videoWidth && "videoHeight" in source && source.videoHeight) {
            this.width = source.videoWidth;
            this.height = source.videoHeight;
        }
        this.ratio = this.width / this.height;
        for (let i = 0; i < this.len; i++) {
            if (this.textures[i]) {
                gl.deleteTexture(this.textures[i]);
                this.textures[i] = null;
            }
            if (this.framebuffers[i]) {
                gl.deleteFramebuffer(this.framebuffers[i]);
                this.framebuffers[i] = null;
            }
        }
        for (let i = 0; i < this.len; i++) {
            const [framebuffer, texture] = this.synth.renderer.createFramebufferAndTexture(source, this.width, this.height, this._internalFormat, this._type, this._format, true, textureParams);
            texture["name"] = this.len > 1 ? this.name + "_" + i : this.name;
            this.textures[i] = texture;
            this.framebuffers[i] = framebuffer;
        }
        this.source = source;
        //this.pixelsData = new (glTypeToTypedArray(this._type))(this.width * this.height * this.components())
        this.pixelsData = new (glTypeToTypedArray(this._type))(this.width * this.height * 4);
        // this.needsUpdate = source !== null
        this.updates = 0;
        return this;
    }
    components() {
        const gl = this.synth.renderer.gl;
        switch (this._format) {
            case gl.ALPHA:
            case gl.LUMINANCE:
            case gl.RED:
                return 1;
            case gl.LUMINANCE_ALPHA:
            case gl.RG:
                return 2;
            case gl.RGB:
                return 3;
            case gl.RGBA:
                return 4;
            default:
                throw new Error("Formato di texture sconosciuto: " + this._format);
        }
    }
    update() {
        // if (!this.source || !this.needsUpdate) return this
        if (!this.source)
            return this;
        const gl = this.synth.renderer.gl;
        gl.bindTexture(gl.TEXTURE_2D, this.texture());
        if (this.source instanceof Buffer) {
            gl.texImage2D(gl.TEXTURE_2D, 0, this._internalFormat, this.width, this.height, 0, this._format, this._type, this.source._data);
        }
        else {
            gl.texImage2D(gl.TEXTURE_2D, 0, this._internalFormat, this._format, this._type, this.source);
        }
        //gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, this.width, this.height, this._format, this._type, this.source)
        this.updates++;
        return this;
    }
    // forceUpdate() {
    // 	if (this.attachedShader) this.attachedShader.forceUpdate()
    // }
    texture(i = this.index, setIndex = false) {
        const index = (i + this.len) % this.len;
        if (setIndex)
            this.index = index;
        return this.textures[index];
    }
    framebuffer(i = this.index, setIndex = false) {
        const index = (i + this.len) % this.len;
        if (setIndex)
            this.index = index;
        return this.framebuffers[index];
    }
    next() {
        this.index = (this.index + 1) % this.len;
        return this;
    }
    length(len) {
        this.len = len;
        return this.create(this.source);
    }
    pixels(data = this.pixelsData, flipY = true) {
        const gl = this.synth.renderer.gl;
        const width = this.width;
        const height = this.height;
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer());
        gl.readPixels(0, 0, width, height, this._format, this._type, data);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        // Flip vertically
        if (flipY) {
            const row = width * 4;
            //const row = width * this.components()
            const halfHeight = Math.floor(height / 2);
            for (let i = 0; i < halfHeight; i++) {
                const start = i * row;
                const end = (height - i - 1) * row;
                const temp = data.slice(start, start + row);
                data.copyWithin(start, end, end + row);
                data.set(temp, end);
            }
        }
        return data;
    }
    resize(width, height) {
        if (width === this.width && height === this.height)
            return this;
        this.width = width;
        this.height = height;
        this.ratio = this.width / this.height;
        if (this.source &&
            !(this.source instanceof Buffer ||
                this.source instanceof ImageBitmap ||
                this.source instanceof ImageData ||
                this.source instanceof VideoFrame)) {
            this.source.width = width;
            this.source.height = height;
        }
        this.create(this.source);
        return this;
    }
    toGLSLSource() {
        return new GLSLSource(this.synth, srcFunction, [this]);
    }
    init(source) {
        if (source instanceof Texture) {
            source.out(this, 0, 0, source.width, source.height, 0, 0, this.width, this.height);
            return this;
        }
        this.source = source;
        const gl = this.synth.renderer.gl;
        gl.bindTexture(gl.TEXTURE_2D, this.texture());
        if (this.source instanceof Buffer) {
            gl.texImage2D(gl.TEXTURE_2D, 0, this._internalFormat, this.width, this.height, 0, this._format, this._type, this.source._data);
        }
        else {
            gl.texImage2D(gl.TEXTURE_2D, 0, this._internalFormat, this._format, this._type, this.source);
        }
        //gl.texStorage2D(gl.TEXTURE_2D, 1, this._internalFormat, this.width, this.height)
        //if (source) gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, this.width, this.height, this._format, this._type, source)
        gl.bindTexture(gl.TEXTURE_2D, null);
        this.updates++;
        return this;
    }
    out(texture, sx = 0, sy = 0, sWidth = this.width, sHeight = this.height, dx = sx, dy = sy, dWidth = texture.width, dHeight = texture.height) {
        const gl = this.synth.renderer.gl;
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, this.framebuffer());
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, texture.framebuffer());
        gl.blitFramebuffer(sx, sy, sx + sWidth, sy + sHeight, dx, dy, dx + dWidth, dy + dHeight, gl.COLOR_BUFFER_BIT, 
        //gl.NEAREST
        gl.LINEAR);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);
        texture.updates++;
        return this;
    }
    toImage() {
        const data = this.pixels();
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = this.width;
        canvas.height = this.height;
        const imageData = ctx.createImageData(this.width, this.height);
        imageData.data.set(data);
        ctx.putImageData(imageData, 0, 0);
        const img = new Image();
        img.src = canvas.toDataURL("image/png");
        return img;
    }
    clear() {
        const gl = this.synth.renderer.gl;
        for (let i = 0; i < this.len; i++) {
            if (this.textures[i]) {
                gl.deleteTexture(this.textures[i]);
                this.textures[i] = null;
            }
            if (this.framebuffers[i]) {
                gl.deleteFramebuffer(this.framebuffers[i]);
                this.framebuffers[i] = null;
            }
        }
    }
}
